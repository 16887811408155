import type { LabelTableRow } from '../models/labels-cashflow-display';

interface LoadingRowData {
  attribute: string;
  [key: string]: string;
}

export const generateLoadingBalanceData = (numberOfColumns: number): LoadingRowData[] => {
  const createRow = (attribute: string): LoadingRowData => ({
    attribute,
    ...Object.fromEntries(
      Array.from({ length: numberOfColumns }, (_, i) => [`col${String(i)}`, ''])
    ),
  });
  return [createRow('header'), createRow('body')];
};

export const generateLoadingLabelsData = (
  numberOfColumns: number,
  numberOfRows = 10
): LabelTableRow[] => {
  const createRow = (attribute: string): LabelTableRow => ({
    id: attribute,
    attribute,
    color: '',
    type: 'labelsList',
    columns: Array.from({ length: numberOfColumns }, () => ({
      amount: '',
      interval: {
        start: 0,
        end: 0,
      },
    })),
  });
  return Array.from({ length: numberOfRows }, (_, i) => createRow(`row${String(i)}`));
};

export const generateLoadingHeaders = (numberOfColumns: number): string[] =>
  Array.from({ length: numberOfColumns }, (_, i) => `col${String(i)}`);
