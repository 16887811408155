import React, { type ReactNode } from 'react';
import cx from 'clsx';
import { Button } from 'design-system-kit';
import { CrossOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface HeaderProps {
  numberOfTransactions: number;
  onClose: () => void;
}

export function Header({ numberOfTransactions, onClose }: HeaderProps): ReactNode {
  return (
    <header className={cx(styles.header)} data-testid="header">
      <h1 className={cx(styles.title)}>
        {numberOfTransactions} {numberOfTransactions > 1 ? 'Transactions' : 'Transaction'}
      </h1>
      <Button variant="tertiary" iconOnly onPress={onClose} data-testid="close-button">
        <CrossOutlined />
      </Button>
    </header>
  );
}
