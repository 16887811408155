import { useEffect, useState, type ReactNode } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnPinningState,
  type RowSelectionState,
} from '@tanstack/react-table';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import cx from 'clsx';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import { HeaderCell } from './components/header-cell';
import styles from './styles.strict-module.css';
import { BodyCell } from './components/body-cell';

interface TableProps<TData, TValue> {
  data: TData[];
  columns: ColumnDef<TData, TValue>[];
  defaultColumnOrder: string[];
}

type TablesPreferences = {
  columnOrder?: string[];
  columnSizing?: Record<string, number>;
} | null;

export function DataTable<TData extends { id: string }, TValue>({
  data,
  columns,
  defaultColumnOrder,
}: TableProps<TData, TValue>): ReactNode {
  const tablesPreferences = JSON.parse(
    localStorage.getItem('tablesPreferences') ?? '{}'
  ) as TablesPreferences;
  const rightAlignedHeaders = ['amount', 'settledBalance'];

  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['bulk-select', 'transaction'],
  });
  const [columnOrder, setColumnOrder] = useState<string[]>(
    () => tablesPreferences?.columnOrder ?? defaultColumnOrder
  );

  // handles selection at the table level
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  // handles selection at the context level
  const { setSelection, shouldResetSelection, resetSelection } =
    bulkSelectionManager.useBulkSelection();

  const table = useReactTable({
    data,
    columns,
    state: { columnPinning, columnOrder, rowSelection },
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    getRowId: row => row.id,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onColumnPinningChange: setColumnPinning,
    onColumnOrderChange: setColumnOrder,
  });

  useEffect(() => {
    setSelection(Object.keys(rowSelection));
  }, [rowSelection, setSelection]);

  useEffect(() => {
    if (shouldResetSelection) {
      table.resetRowSelection();
      resetSelection();
    }
  }, [shouldResetSelection, table, resetSelection]);

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(active.id as string);
      const newIndex = columnOrder.indexOf(over.id as string);
      const newColumnOrder = arrayMove(columnOrder, oldIndex, newIndex);
      setColumnOrder(newColumnOrder);
      const newPref = { ...tablesPreferences, columnOrder: newColumnOrder };
      localStorage.setItem('tablesPreferences', JSON.stringify(newPref));
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <table className={cx(styles['data-table'])}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                {headerGroup.headers.map(header => (
                  <HeaderCell
                    header={header}
                    key={header.id}
                    {...(rightAlignedHeaders.includes(header.id) && { align: 'right' })}
                  />
                ))}
              </SortableContext>
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length ? (
            table.getRowModel().rows.map(row => (
              <tr data-testid="transaction-row" key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <SortableContext
                    items={columnOrder}
                    key={cell.id}
                    strategy={horizontalListSortingStrategy}
                  >
                    <BodyCell cellIndex={index} cell={cell} key={cell.id} />
                  </SortableContext>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <p>No results</p>
            </tr>
          )}
        </tbody>
      </table>
    </DndContext>
  );
}
