import type { ReactNode } from 'react';
import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { Popover, PopoverHeader, PopoverSection } from 'qonto/react/components/table-v2/popover';
import { Filter } from 'qonto/react/assets/icons/filter';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { External } from 'qonto/react/assets/icons';
import type { TransactionInitiator } from 'qonto/react/models/transaction';
import { Member } from '../member';
import { MembershipItem } from './membership-item';
import styles from './styles.strict-module.css';
import { MembershipAction } from './membership-action';

interface MemberCellPopoverProps {
  membership: TransactionInitiator;
}

export function MembershipCellPopover({ membership }: MemberCellPopoverProps): ReactNode {
  const router = useRouter();
  const { organization } = useOrganizationManager();
  const capitalize = (word: string): string => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const team = membership.team.name ? capitalize(membership.team.name) : '-';

  const filterByMember = (): void => {
    void router.push(
      `/organizations/${organization.slug}/transactions-v2?initiator=${membership.id}`
    );
  };

  const viewMemberProfile = (): void => {
    void router.push(`/organizations/${organization.slug}/members/active/${membership.id}`);
  };

  return (
    <Popover>
      <PopoverHeader>
        <Member
          fullName={membership.fullName}
          avatarUrl={membership.avatar.url}
          data-testid="member"
        />
      </PopoverHeader>
      <PopoverSection className={styles.section}>
        <MembershipItem information="Email" value={membership.email} data-testid="email-item" />
        <MembershipItem
          information="Role"
          value={capitalize(membership.role)}
          data-testid="role-item"
        />
        <MembershipItem information="Team" value={team} data-testid="team-item" />
      </PopoverSection>
      <PopoverSection className={styles.section}>
        <MembershipAction
          text="Filter by member"
          icon={<Filter />}
          onPress={filterByMember}
          data-testid="filter-action"
        />
        <MembershipAction
          text="View member's profile"
          icon={<External />}
          onPress={viewMemberProfile}
          data-testid="view-profile-action"
        />
      </PopoverSection>
    </Popover>
  );
}
