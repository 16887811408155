import type { ReactNode } from 'react';
import cx from 'clsx';
import { ArrowLeft, ArrowRight } from 'qonto/react/assets/icons';
import type { SearchTransactionsMeta } from 'qonto/react/graphql';
import styles from './styles.strict-module.css';

interface PaginationProps {
  responseMeta: SearchTransactionsMeta;
  updatePerPage: (pageSize: number) => void;
  updatePage: (page: number) => void;
}

function PerPageOption({
  perPage,
  updatePerPage,
}: {
  perPage: number;
  updatePerPage: (pageSize: number) => void;
}): ReactNode {
  return (
    <button
      className={cx(styles.option)}
      type="button"
      data-testid={`per-page-option-${String(perPage)}`}
      onClick={() => {
        updatePerPage(perPage);
      }}
    >
      {perPage}
    </button>
  );
}

export function Pagination({
  updatePerPage,
  updatePage,
  responseMeta,
}: PaginationProps): ReactNode {
  const canGoToPreviousPage = Boolean(responseMeta.prevPage);
  const canGoToNextPage = Boolean(responseMeta.nextPage);

  const goToPreviousPage = (): void => {
    if (responseMeta.prevPage) {
      updatePage(responseMeta.prevPage);
    }
  };

  const goToNextPage = (): void => {
    if (responseMeta.nextPage) {
      updatePage(responseMeta.nextPage);
    }
  };

  return (
    <div className={cx(styles.pagination)} data-testid="pagination">
      <div className={cx(styles['per-page-controls'])} data-testid="per-page-controls">
        <div className={cx(styles.options)} data-testid="per-page-options">
          {[25, 50, 100].map((perPage: number) => (
            <PerPageOption key={perPage} perPage={perPage} updatePerPage={updatePerPage} />
          ))}
        </div>
        <div className={cx(styles['per-page-description'])} data-testid="per-page-label">
          items per page
        </div>
      </div>
      <div
        className={cx(styles['current-page-description'], 'body-2')}
        data-testid="current-page-description"
      >
        {getCurrentPageDescription(responseMeta)}
      </div>
      <div className={cx(styles.options)} data-testid="navigation-controls">
        <button
          className={cx(styles.option, ['btn', 'btn--icon-only', 'btn--tertiary'])}
          type="button"
          data-testid="previous-page"
          aria-label="Go to previous page"
          disabled={!canGoToPreviousPage}
          onClick={goToPreviousPage}
        >
          <ArrowLeft />
        </button>
        <button
          className={cx(styles.option, ['btn', 'btn--icon-only', 'btn--tertiary'])}
          type="button"
          data-testid="next-page"
          aria-label="Go to next page"
          disabled={!canGoToNextPage}
          onClick={goToNextPage}
        >
          <ArrowRight />
        </button>
      </div>
    </div>
  );
}

function getCurrentPageDescription(meta: SearchTransactionsMeta): string {
  const currentPage = meta.currentPage;
  const perPage = meta.perPage;
  const totalCount = meta.totalCount;
  const offset = currentPage * perPage;
  const end = Math.min(offset, totalCount);
  const start = totalCount && offset - perPage + 1;

  return `${String(start)}-${String(end)} of ${String(totalCount)}`;
}
