import cx from 'clsx';
import { type CountryCode, Flag, Spinner } from 'design-system-kit';
import { type ReactNode, useMemo } from 'react';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { formatAccountInformation } from 'qonto/utils/international-out/format';
import styles from './styles.strict-module.css';

interface Beneficiary {
  name: string;
  country: CountryCode;
  currency: string;
  paymentType: string;
  accountIdentifier: string;
  bankIdentifier: string;
  branchIdentifier?: string;
}

interface InformationProps {
  beneficiary: Beneficiary;
  isLoading?: boolean;
}

export function Information({ beneficiary, isLoading = false }: InformationProps): ReactNode {
  const { exists, formatCountry, locale, t } = useIntl();

  const formattedAccountInformation = formatAccountInformation({
    accountIdentifier: beneficiary.accountIdentifier,
    bankIdentifier: beneficiary.bankIdentifier,
  });

  const paymentType = useMemo(() => {
    const translationKey = `international-out.dynamic-form.payment-type.${beneficiary.paymentType}`;

    if (typeof locale === 'string' && exists(translationKey, locale)) {
      return t(translationKey);
    }

    return null;
  }, [beneficiary.paymentType, exists, locale, t]);

  return (
    <div className={cx(styles.wrapper)}>
      <div>
        <span className={cx(styles.name, 'body-1 mr-8')} data-testid="name">
          {beneficiary.name}
        </span>

        <div className={cx(styles.account, 'body-2 mr-16')}>
          {paymentType ? (
            <span className={cx(styles['payment-type'], 'body-2')} data-testid="payment-type">
              {paymentType}:{' '}
            </span>
          ) : null}
          <span className={cx(styles['account-number'], 'body-2')} data-testid="account-numbers">
            {formattedAccountInformation}
          </span>
        </div>
      </div>

      <div className={cx(styles.destination)}>
        <Flag
          code={beneficiary.country}
          style={{ borderRadius: '2px' }}
          aria-label={formatCountry(beneficiary.country)}
          data-testid="country"
        />
        <div className={cx(styles['currency-wrapper'])}>
          {isLoading ? (
            <Spinner color="primary-a" size="small" data-testid="spinner" />
          ) : (
            <span className={cx(styles.currency)} data-testid="currency">
              {beneficiary.currency}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
