import { useState, type ReactNode } from 'react';
import {
  Calculator,
  LightBulb,
  Rocket,
  Tag,
  Restaurant,
  Basket,
  Jerrican,
  ThreeStars,
  Computer,
  Tool,
  Pin,
  Key,
  Shield,
  Box,
  Globe,
  Scale,
  Institution,
  People,
  Megaphone,
  Hook,
  Atm,
  Briefcase,
  Wallet,
  Safe,
  Target,
  MoneyBag,
  ArrowBack,
  Receipt,
  ProcessingOutlined,
  PlusSign,
} from 'qonto/react/assets/icons';

interface CategoryDetails {
  title: string;
  icon: ReactNode;
}

type CategoryMap = Record<string, CategoryDetails>;
type CategoriesCollection = (CategoryDetails & { id: string })[];

export const categoryMap: CategoryMap = {
  restaurant_and_bar: {
    title: 'Restaurant',
    icon: <Restaurant />,
  },
  food_and_grocery: {
    title: 'Food',
    icon: <Basket />,
  },
  transport: {
    title: 'Transport',
    icon: <Rocket />,
  },
  gas_station: {
    title: 'Gas',
    icon: <Jerrican />,
  },
  hotel_and_lodging: {
    title: 'Hotel',
    icon: <ThreeStars />,
  },
  it_and_electronics: {
    title: 'IT',
    icon: <Computer />,
  },
  hardware_and_equipment: {
    title: 'Hardware',
    icon: <Tool />,
  },
  office_supply: {
    title: 'Office Supply',
    icon: <Pin />,
  },
  office_rental: {
    title: 'Office Rental',
    icon: <Key />,
  },
  utility: {
    title: 'Utility',
    icon: <LightBulb />,
  },
  insurance: {
    title: 'Insurance',
    icon: <Shield />,
  },
  logistics: {
    title: 'Logistics',
    icon: <Box />,
  },
  online_service: {
    title: 'Online Service',
    icon: <Globe />,
  },
  legal_and_accounting: {
    title: 'Legal',
    icon: <Scale />,
  },
  finance: {
    title: 'Finance',
    icon: <Institution />,
  },
  tax: {
    title: 'Tax',
    icon: <Calculator />,
  },
  salary: {
    title: 'Salary',
    icon: <People />,
  },
  marketing: {
    title: 'Marketing',
    icon: <Megaphone />,
  },
  manufacturing: {
    title: 'Manufacturing',
    icon: <Hook />,
  },
  atm: {
    title: 'ATM',
    icon: <Atm />,
  },
  other_service: {
    title: 'Other Service',
    icon: <Briefcase />,
  },
  other_expense: {
    title: 'Other Expense',
    icon: <Wallet />,
  },
  treasury_and_interco: {
    title: 'Treasury',
    icon: <Safe />,
  },
  sales: {
    title: 'Sales',
    icon: <Target />,
  },
  other_income: {
    title: 'Other Income',
    icon: <MoneyBag />,
  },
  refund: {
    title: 'Refund',
    icon: <ArrowBack />,
  },
  fees: {
    title: 'Fees',
    icon: <Receipt />,
  },
  voucher: {
    title: 'Voucher',
    icon: <Tag />,
  },
  subscription: {
    title: 'Subscription',
    icon: <Tag />,
  },
  pending: {
    title: 'Pending',
    icon: <ProcessingOutlined />,
  },
  fallback: {
    title: 'Other',
    icon: <Tag />,
  },
};

export const getCategoriesCollection = (): CategoriesCollection => {
  return Object.entries(categoryMap).map(([id, value]) => ({ id, ...value }));
};

export const getCategoryAttrs = (category?: string | null): { title: string; icon: ReactNode } => {
  if (!category) {
    return {
      title: 'Add category',
      icon: <PlusSign />,
    };
  }

  const attrs = categoryMap[category.toLowerCase()];

  if (attrs) return attrs;

  return {
    title: transformToNormalCasing(category),
    icon: <Tag />,
  };
};

function transformToNormalCasing(title: string): string {
  return title
    .split('_')
    .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
    .join(' ');
}

export function useCategoryFilter(): {
  filteredList: CategoriesCollection;
  filter: (value: string) => void;
  resetFilter: () => void;
} {
  const list = getCategoriesCollection();
  const [filteredList, setFilteredList] = useState(list);

  const filter = (value: string): void => {
    if (value === '') {
      resetFilter();
    } else {
      setFilteredList(list.filter(c => c.title.toLowerCase().includes(value.toLowerCase())));
    }
  };

  const resetFilter = (): void => {
    setFilteredList(list);
  };

  return {
    filteredList,
    filter,
    resetFilter,
  };
}
