import type { ComponentPropsWithRef, ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { Popover, PopoverHeader, PopoverSection } from 'qonto/react/components/table-v2/popover';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import type { StatusHistoryStatus, StatusHistory } from 'qonto/react/constants';
import { statusHistoryMap } from '../status-map';
import styles from './styles.strict-module.css';

interface StatusCellPopoverProps {
  statusHistory: StatusHistory[];
  currentStatus: ReactNode;
}

export function StatusCellPopover({
  statusHistory,
  currentStatus,
}: StatusCellPopoverProps): ReactNode {
  return (
    <Popover>
      <PopoverHeader>{currentStatus}</PopoverHeader>
      <PopoverSection>
        <header data-testid="popover-title" className={cx(styles.title)}>
          History
        </header>
        <StatusTimeline statusHistory={statusHistory} />
      </PopoverSection>
    </Popover>
  );
}

export function StatusTimeline({ statusHistory }: { statusHistory: StatusHistory[] }): ReactNode {
  const reversedStatusHistory = statusHistory.toReversed();
  return (
    <ol data-testid="status-timeline" className={cx(styles.timeline)}>
      {reversedStatusHistory.map((item, index) => {
        return (
          <StatusItem
            data-testid={`item-${item.status.toLowerCase()}-${String(index)}`}
            key={item.status}
            {...item}
          />
        );
      })}
    </ol>
  );
}

interface StatusItemProps extends ComponentPropsWithRef<'li'> {
  status: StatusHistoryStatus;
  createdAt: string;
  'data-testid': string;
}

function StatusItem({ status, createdAt, ...props }: StatusItemProps): ReactNode {
  const { primaryLocale } = useIntl();

  const formattedCreatedAt = dateToken({
    date: createdAt,
    locale: primaryLocale,
    token: 'date-time-s',
  });

  return (
    <li className={cx(styles['timeline-item'])} {...props}>
      <span data-testid="item-icon" aria-hidden className={cx(styles['timeline-icon-container'])}>
        {statusHistoryMap[status].icon}
      </span>
      <div data-testid="item-content" className={cx(styles['timeline-item-content'])}>
        <div data-testid="item-title" className={cx(styles['timeline-item-title'])}>
          {statusHistoryMap[status].title}
        </div>
        <time data-testid="item-subtitle" className={cx(styles['timeline-item-subtitle'])}>
          {formattedCreatedAt}
        </time>
      </div>
    </li>
  );
}
