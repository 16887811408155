import { type ReactNode } from 'react';
import cx from 'clsx';
import { useSearchTransactions } from 'qonto/react/hooks/use-search-transactions';
import { Pagination } from 'qonto/react/components/transactions/table/pagination';
import { useFetchLabelLists } from 'qonto/react/hooks/use-fetch-label-lists';
import { BulkSelectionProvider } from 'qonto/react/contexts/bulk-selection-context';
import type { SearchTransactionsMeta, TransactionsSearch } from 'qonto/react/graphql';
import { DataTable } from '../../table-v2/data-table';
import { generateColumns } from './columns';
import styles from './styles.strict-module.css';
import { BulkSidePanel } from './bulk-side-panel';

interface TransactionsTableProps {
  bankAccountsQPValue?: string;
  organizationId: string;
  page: number;
  perPage: number;
  searchQuery: string;
  filterGroup: TransactionsSearch['filterGroup'];
  updatePage: (page: number) => void;
  updatePerPage: (perPage: number) => void;
  updateMeta: (meta: SearchTransactionsMeta) => void;
}

export function TransactionsTable({
  bankAccountsQPValue,
  filterGroup,
  organizationId,
  page,
  perPage,
  updatePage,
  updatePerPage,
  searchQuery = '',
  updateMeta,
}: TransactionsTableProps): ReactNode {
  const pagination = { page, perPage };
  const query: TransactionsSearch = {
    search: searchQuery,
    ...(bankAccountsQPValue ? { bankAccountIds: [bankAccountsQPValue] } : {}),
    filterGroup,
  };

  const {
    data: labelLists,
    isLoading: isLabelListsLoading,
    isError: isLabelListsError,
  } = useFetchLabelLists(organizationId);

  const { data, isLoading, isError } = useSearchTransactions(updateMeta, {
    query,
    pagination,
  });

  if (isLoading || isLabelListsLoading) {
    return <div data-testid="transactions-table-loading">Loading...</div>;
  }

  if (isError || isLabelListsError) {
    return <div data-testid="transactions-table-error">Error fetching data</div>;
  }

  if (data && labelLists) {
    const { columns, defaultColumnOrder } = generateColumns(labelLists);
    return (
      <BulkSelectionProvider>
        <div data-testid="transactions-table" className={cx(styles.container)}>
          <DataTable
            columns={columns}
            data={data.transactions}
            defaultColumnOrder={defaultColumnOrder}
          />
        </div>
        {data.meta.totalPages > 1 && (
          <Pagination
            updatePage={updatePage}
            updatePerPage={updatePerPage}
            responseMeta={data.meta}
          />
        )}
        <BulkSidePanel labelLists={labelLists} organizationId={organizationId} />
      </BulkSelectionProvider>
    );
  }

  return null;
}
