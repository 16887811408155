import { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export interface DataWithIconProps {
  title?: string;
  subtitle?: string | null;
  icon?: ReactNode;
  type?: 'info' | 'warning' | 'error';
}

export function DataWithIconCell({ icon, title, subtitle, type }: DataWithIconProps): ReactNode {
  return (
    <div
      data-testid="data-with-icon-cell"
      data-test-type={type ?? ''}
      className={cx(styles.container)}
    >
      {icon ? (
        <div data-testid="cell-icon" className={cx(styles.icon)} aria-hidden="true">
          {icon}
        </div>
      ) : null}
      <div className={cx('title-group')}>
        <p data-testid="cell-title" className="body-2">
          {title}
        </p>
        {subtitle ? (
          <p data-testid="cell-subtitle" className={cx(styles.subtitle, type ?? '')}>
            {subtitle}
          </p>
        ) : null}
      </div>
    </div>
  );
}
