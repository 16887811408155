import { type ReactNode } from 'react';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import styles from './styles.strict-module.css';

interface LabelCellProps {
  title: string;
  type?: 'label' | 'labelsList';
  color?: string;
}

export function LabelCell({ title, type = 'labelsList', color = '' }: LabelCellProps): ReactNode {
  const { t } = useIntl();
  const intTitle = title === 'Unlabeled' ? t('cash-flow.table.header-column.unlabeled') : title;
  const opacity = type === 'labelsList' ? 1 : 0.3;

  return (
    <div className={styles.labelCell}>
      <span
        className={styles.colorDot}
        style={{ backgroundColor: color, opacity }}
        data-testid="label-color-dot"
      />
      <span>{intTitle}</span>
    </div>
  );
}
