import { useEffect, useMemo, type ReactNode } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
  type ColumnDef,
} from '@tanstack/react-table';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import {
  generateLoadingHeaders,
  generateLoadingLabelsData,
} from '../../utils/generate-loading-data';
import type { LabelTableRow } from '../../models/labels-cashflow-display';
import { TableCell } from '../shared/table-cell';
import styles from './styles.strict-module.css';
import { LabelCell } from './label-cell';
import { BalanceCell } from './balance-cell';
import { HeaderCell } from './header-cell';

const buildTableSchema = (
  data: LabelTableRow[],
  isLoading: boolean,
  numberOfColumns: number,
  copy: Record<string, string>
): {
  tableData: LabelTableRow[];
  columns: ColumnDef<LabelTableRow>[];
} => {
  const headersData = isLoading
    ? generateLoadingHeaders(numberOfColumns)
    : Array.from({ length: numberOfColumns }, (_, index) => String(index));
  const tableData = isLoading ? generateLoadingLabelsData(numberOfColumns) : data;

  const columnHelper = createColumnHelper<LabelTableRow>();
  const columns = [
    columnHelper.accessor('attribute', {
      header: copy.analyticLabels,
      cell: info => (
        <LabelCell
          title={info.getValue()}
          type={info.row.original.type}
          color={info.row.original.color}
        />
      ),
    }),
    ...headersData.map((col, index) =>
      columnHelper.accessor('columns', {
        id: col,
        header: ({ table }) => {
          const interval = table.getRowModel().rows[0]?.original.columns[index]?.interval;
          return <HeaderCell interval={interval} />;
        },
        cell: info => {
          return (
            <BalanceCell
              amount={info.getValue()[index]?.amount}
              interval={info.getValue()[index]?.interval}
              type={info.row.original.type}
              labelEntityId={info.row.original.id}
            />
          );
        },
      })
    ),
  ] as ColumnDef<LabelTableRow>[];

  return {
    tableData,
    columns,
  };
};

interface LabelsTableProps {
  data: LabelTableRow[];
  isLoading?: boolean;
  numberOfColumns?: number;
  onUndefinedValues: (data: LabelTableRow[]) => void;
}
export function LabelsTable({
  data,
  isLoading = false,
  numberOfColumns = 6,
  onUndefinedValues,
  ...props
}: LabelsTableProps): ReactNode {
  const { t } = useIntl();
  const copy = useMemo(
    () => ({ analyticLabels: t('cash-flow.table.header-column.analytic-labels') }),
    [t]
  );

  const hasUndefinedLabelValue = data.find(tableRow => {
    const hasUndefinedSubRowsColumns = tableRow.subRows?.find(row =>
      row.columns.find(column => {
        return !column.amount || isNaN(Number(column.amount));
      })
    );
    const hasUndefinedColumns = tableRow.columns.find(
      column => !column.amount || isNaN(Number(column.amount))
    );

    return hasUndefinedSubRowsColumns ?? hasUndefinedColumns;
  });

  useEffect(() => {
    if (hasUndefinedLabelValue) {
      onUndefinedValues(data);
    }
  }, [data, hasUndefinedLabelValue, onUndefinedValues]);

  const { tableData, columns } = useMemo(
    () => buildTableSchema(data, isLoading, numberOfColumns, copy),
    [data, isLoading, numberOfColumns, copy]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    initialState: {
      expanded: true,
    },
    getCoreRowModel: getCoreRowModel(),
    getSubRows: row => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <table className={styles.labelsTable} aria-busy={isLoading} aria-live="polite" {...props}>
      <colgroup>
        <col style={{ width: '250px' }} />
        {Array.from({ length: numberOfColumns }, (_, index) => (
          <col key={index} style={{ width: '100%' }} />
        ))}
      </colgroup>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => {
              const isFirstColumn = index === 0;
              return (
                <th key={header.id} scope="col" className={styles.colHeader}>
                  {header.isPlaceholder ? null : (
                    <TableCell
                      isLoading={isFirstColumn ? false : isLoading}
                      align={isFirstColumn ? 'left' : 'center'}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableCell>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell, index) => {
              if (index === 0) {
                return (
                  <th
                    key={cell.id}
                    scope="row"
                    className={styles.rowHeader}
                    data-testid="row-header"
                  >
                    <TableCell isLoading={isLoading} isLabel align="left">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  </th>
                );
              }
              return (
                <td key={cell.id} className={styles.rowCell}>
                  <TableCell isLoading={isLoading}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
