import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { Link } from 'react-aria-components';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface SubscriptionHeaderDeactivatedProps {
  readonly registerLink: string;
  readonly deactivationDate?: string;
}

export function SubscriptionHeaderDeactivated(
  props: SubscriptionHeaderDeactivatedProps
): ReactNode {
  const { registerLink, deactivationDate } = props;
  const { t, locale } = useIntl();

  const getFormattedDeactivationDate = (): string | undefined => {
    if (!deactivationDate) {
      return;
    }

    return dateToken({
      date: deactivationDate,
      locale: locale as string,
      token: 'date-year-l',
    });
  };

  return (
    <>
      <div className={cx(styles['card-header'], 'mb-8')}>
        <h2 className="title-3" data-test-settings-subscription-plan-title-account-closed>
          {t('subscription.account-closing.steps.closing')}
        </h2>
      </div>
      <p className="body-1 mb-16" data-test-settings-subscription-plan-description-account-closed>
        <span className={cx(styles['subtitle-account-closed'])}>
          {t('subscription.account-closing.post-closing.requirements.account-is-closed.part-1')}
          <span className={cx(styles.date)} data-test-settings-subscription-plan-date-closed>
            {getFormattedDeactivationDate()}
          </span>
          {t('subscription.account-closing.post-closing.requirements.account-is-closed.part-2')}
        </span>
      </p>

      <Link
        href={registerLink}
        className="btn btn--primary mr-16"
        data-test-settings-subscription-open-new-account-cta
      >
        {t('subscription.account-closing.post-closing.requirements.open-account')}
      </Link>
    </>
  );
}
