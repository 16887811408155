import { type ReactNode } from 'react';
import { useFetchLabelLists } from 'qonto/react/hooks/use-fetch-label-lists';
import type { MonthlyBalance } from '../../api/balance';
import type { LabelStatisticsResponse, UnlabeledStatisticsResponse } from '../../api/labels';
import type { StatisticsMonthResponse } from '../../api/statistics';
import { BalanceTable } from '../balance-table';
import { useBalances } from '../../hooks/use-balances';
import { useLabelCashflows } from '../../hooks/use-label-cashflows';
import type { LabelTableRow } from '../../models/labels-cashflow-display';
import { LabelsTable } from '../labels-table';
import styles from './styles.strict-module.css';

interface TablesProps {
  organizationId: string;
  balanceStatistics: StatisticsMonthResponse<MonthlyBalance>;
  labelStatistics: LabelStatisticsResponse;
  unlabeledStatistics: UnlabeledStatisticsResponse;
  offset?: number;
  displayedMonths?: number;
  isLoading?: boolean;
  isUpdating?: boolean;
  isError?: boolean;
  onUndefinedValues: (data: LabelTableRow[]) => void;
}

export function TablesLayout({
  organizationId,
  balanceStatistics,
  labelStatistics,
  unlabeledStatistics,
  offset = 0,
  displayedMonths = 6,
  isLoading = false,
  isUpdating = false,
  isError = false,
  onUndefinedValues,
}: TablesProps): ReactNode {
  const {
    data: labelLists,
    isLoading: isLoadingLabelLists,
    isError: isErrorLabelLists,
  } = useFetchLabelLists(organizationId);
  const labelTableData = useLabelCashflows(
    offset,
    displayedMonths,
    labelStatistics,
    unlabeledStatistics,
    labelLists
  );

  const { startBalances, endBalances } = useBalances(balanceStatistics, offset, displayedMonths);

  if (isError || isErrorLabelLists)
    return <p data-testid="tables-layout-error">Something went wrong</p>;

  return (
    <section className={styles.wrapper} data-testid="tables-layout">
      <BalanceTable
        type="start"
        data={startBalances}
        numberOfColumns={displayedMonths}
        isLoading={isLoading || isUpdating}
        data-testid="start-balance-table"
      />
      <LabelsTable
        data={labelTableData}
        isLoading={isLoading || isLoadingLabelLists}
        numberOfColumns={displayedMonths}
        onUndefinedValues={onUndefinedValues}
        data-testid="label-table"
      />
      <BalanceTable
        type="end"
        data={endBalances}
        numberOfColumns={displayedMonths}
        isLoading={isLoading || isUpdating}
        data-testid="end-balance-table"
      />
    </section>
  );
}
