import type { PropsWithChildren, ReactNode } from 'react';
import {
  PolymorphicIntlContextProvider,
  PolymorphicRouterContextProvider,
} from '@qonto/react-migration-toolkit/react/providers';
import { useEmberIntl, useEmberRouter } from '@qonto/react-migration-toolkit/react/hooks/providers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();

export function CustomProviders({ children }: PropsWithChildren): ReactNode {
  const intl = useEmberIntl();
  const router = useEmberRouter();

  return (
    <PolymorphicRouterContextProvider router={router}>
      <PolymorphicIntlContextProvider intl={intl}>
        <QueryClientProvider client={queryClient}>
          {children}
          <ReactQueryDevtools buttonPosition="bottom-left" />
        </QueryClientProvider>
      </PolymorphicIntlContextProvider>
    </PolymorphicRouterContextProvider>
  );
}
