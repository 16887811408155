import { type ReactNode } from 'react';
import cx from 'clsx';
import { Button } from 'react-aria-components';
import { bulkTransactionsManager } from 'qonto/react/contexts/bulk-transactions-context';
import styles from './styles.strict-module.css';

interface FooterProps {
  selectedTransactions: string[];
  onClose: () => void;
}

export function Footer({ onClose, selectedTransactions }: FooterProps): ReactNode {
  const {
    labels: { aggregatedUpdatedLabels, mutationFn: mutateBulkUpdates },
    verificationStatus: { verificationStatusChanged, mutationFn: mutateBulkVerificationStatus },
    category: { categoryChanged },
    isMutating,
  } = bulkTransactionsManager.useBulkTransactions();
  const hasUpdatedLabels = aggregatedUpdatedLabels.length > 0;

  const handleSubmit = (): void => {
    mutateBulkUpdates(selectedTransactions);
    mutateBulkVerificationStatus(selectedTransactions);
  };

  return (
    <div className={cx(styles.footer)} data-testid="footer">
      <Button onPress={onClose} className={cx(styles.button)} data-testid="cancel-button">
        Discard
      </Button>
      <Button
        onPress={handleSubmit}
        isDisabled={
          isMutating || (!hasUpdatedLabels && !verificationStatusChanged && !categoryChanged)
        }
        className={cx(styles.button, styles['button-primary'])}
        data-testid="submit-button"
      >
        {isMutating ? 'Updating...' : 'Apply changes'}
      </Button>
    </div>
  );
}
