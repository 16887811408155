import { useDeepMemo } from 'qonto/react/hooks/use-deep-memo';
import type { MonthlyBalance } from '../api/balance';
import { normalizeBalances } from '../utils/normalize-balances';
import { transposeBalances } from '../utils/transpose-balances';
import type { TransposedData } from '../utils/transpose-table-data';
import type { StatisticsMonthResponse } from '../api/statistics';

export const useBalances = (
  statistics: StatisticsMonthResponse<MonthlyBalance>,
  offset: number,
  displayedMonths: number
): { startBalances: TransposedData[]; endBalances: TransposedData[] } => {
  const selectedBalances: MonthlyBalance[] = useDeepMemo(
    () => statistics.data.display_at_monthly.slice(offset, offset + displayedMonths),
    [statistics, offset, displayedMonths]
  );
  const normalizedBalances = normalizeBalances(selectedBalances);
  const { startBalances, endBalances } = transposeBalances(normalizedBalances);

  return { startBalances, endBalances };
};
