import { type ReactNode } from 'react';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { getCategoryAttrs } from './category-map';
import { CategoryCellPopover } from './popover';

interface CategoryCellProps {
  category?: string | null;
  transactionId: string;
}

export function CategoryCell({ category, transactionId }: CategoryCellProps): ReactNode {
  const { title, icon } = getCategoryAttrs(category);

  return (
    <BaseCell
      data-testid="category-cell"
      popoverSlot={<CategoryCellPopover category={category} transactionId={transactionId} />}
    >
      <DataWithIconCell title={title} icon={icon} />
    </BaseCell>
  );
}
