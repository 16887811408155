import { useState, type ReactNode } from 'react';
import cx from 'clsx';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { type Attachment } from 'qonto/react/graphql';
import { Document, Documents, PlusSign } from 'qonto/react/assets/icons';
import { AttachmentViewer } from 'qonto/react/components/transactions/table/attachment-viewer';
import { EyeOutlined } from 'qonto/react/assets/icons/eye-outlined';
import styles from './styles.strict-module.css';
import { SingleAttachmentCellPopover } from './popover';
import { MultipleAttachmentsCellPopover } from './popover/multiple-attachments-cell-popover';

interface DocumentCellProps {
  attachments: Attachment[];
  required?: boolean;
  lost?: boolean;
}
export function AttachmentCell({
  attachments,
  required = false,
  lost = false,
}: DocumentCellProps): ReactNode {
  const noAttachments = !attachments.length;
  const singleAttachment = attachments.length === 1;

  if (noAttachments) {
    return <NoAttachment required={required} lost={lost} />;
  }

  if (singleAttachment && attachments[0]) {
    return <SingleAttachment attachment={attachments[0]} />;
  }

  return <MultiAttachments attachments={attachments} />;
}

const getMutliProbativeStatus = (
  attachments: Attachment[]
): { pending: number; corrupted: number } =>
  attachments.reduce(
    (acc, attachment) => {
      const { probativeAttachment } = attachment;
      if (probativeAttachment) {
        const { pending, corrupted } = acc;
        if (probativeAttachment.status === 'pending') {
          return { corrupted, pending: pending + 1 };
        }
        if (probativeAttachment.status === 'corrupted') {
          return { pending, corrupted: corrupted + 1 };
        }
      }
      return acc;
    },
    { pending: 0, corrupted: 0 }
  );

interface MultiAttachmentsProps {
  attachments: Attachment[];
}
function MultiAttachments({ attachments }: MultiAttachmentsProps): ReactNode {
  const length = attachments.length.toString();
  const { pending, corrupted } = getMutliProbativeStatus(attachments);
  const type = corrupted ? 'error' : undefined;
  const title = `${length} documents`;

  const getSubtitle = (): string | undefined => {
    if (corrupted) return `${corrupted.toString()} docs not valid`;
    if (pending) return `${pending.toString()} pending for verification`;
  };

  return (
    <BaseCell
      popoverSlot={
        <MultipleAttachmentsCellPopover
          attachments={attachments}
          title={title}
          icon={<Documents />}
          type={type}
        />
      }
    >
      <DataWithIconCell title={title} subtitle={getSubtitle()} icon={<Documents />} type={type} />
    </BaseCell>
  );
}

interface SingleAttachmentProps {
  attachment: Attachment;
}
function SingleAttachment({ attachment }: SingleAttachmentProps): ReactNode {
  const [showPreview, setShowPreview] = useState(false);
  const { file, probativeAttachment } = attachment;
  const { status } = probativeAttachment ?? {};
  const type = status === 'corrupted' ? 'error' : undefined;
  const name = file?.name ?? '';
  const title = name.length > 20 ? `⋅⋅ ${name.slice(-20)}` : name;

  const getSubtitle = (): string | undefined => {
    if (status === 'corrupted') return '1 doc is not valid';
    if (status === 'pending') return 'Pending for verification';
  };

  const actionSlot = (
    <button
      className={cx(styles['preview-trigger'], 'btn', 'btn--icon-only', 'btn--tertiary')}
      onClick={() => {
        setShowPreview(true);
      }}
      type="button"
      data-testid="preview-trigger"
    >
      <EyeOutlined />
    </button>
  );

  return (
    <BaseCell
      actionSlot={actionSlot}
      popoverSlot={
        <SingleAttachmentCellPopover
          attachment={attachment}
          title={title}
          icon={<Document />}
          type={type}
          openPreview={() => {
            setShowPreview(true);
          }}
        />
      }
    >
      <AttachmentViewer
        attachment={attachment}
        isOpen={showPreview}
        onOpenChange={setShowPreview}
      />
      <DataWithIconCell title={title} subtitle={getSubtitle()} icon={<Document />} type={type} />
    </BaseCell>
  );
}

interface NoAttachmentProps {
  required: boolean;
  lost: boolean;
}
function NoAttachment({ required, lost }: NoAttachmentProps): ReactNode {
  const icon = required ? <PlusSign /> : <Document />;
  const type = lost || required ? 'warning' : undefined;
  const title = required ? 'Add file' : 'Not required';
  const getSubtitle = (): string | undefined => {
    if (lost) return 'Document lost';
    if (required) return 'Missing';
  };

  return (
    <BaseCell>
      <DataWithIconCell title={title} icon={icon} subtitle={getSubtitle()} type={type} />
    </BaseCell>
  );
}
