import { flexRender, type Cell } from '@tanstack/react-table';
import React, { type CSSProperties, type ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface BodyCellProps<TData, TValue> {
  cell: Cell<TData, TValue>;
  cellIndex: number;
}

export function BodyCell<TData, TValue>({
  cell,
  cellIndex,
}: BodyCellProps<TData, TValue>): ReactNode {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const isPinned = cell.column.getIsPinned();
  const isFixedColumn = cellIndex === 0;

  const getPadding = (): string => {
    return '0 16px';
  };

  const getZIndex = (): number => {
    if (isPinned) return 2;
    if (isDragging) return 1;
    return 0;
  };

  const dndStyle: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    transform: CSS.Translate.toString(transform),
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    zIndex: getZIndex(),
    position: isPinned ? 'sticky' : 'relative',
    width: `${cell.column.getSize().toString()}px`,
    maxWidth: `${cell.column.getSize().toString()}px`,
    left: isPinned === 'left' ? `${cell.column.getStart('left').toString()}px` : undefined,
    padding: getPadding(),
    borderRight: isFixedColumn ? '1.25px solid var(--border-tertiary)' : 'none',
    minWidth: isFixedColumn ? '300px' : 'none',
    boxShadow: isDragging ? 'var(--shadow-high)' : 'none',
  };

  return (
    <td key={cell.id} ref={setNodeRef} className={cx(styles['table-cell'])} style={{ ...dndStyle }}>
      <div className={cx(styles['table-cell-content'], isFixedColumn && styles['fixed-column'])}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </div>
    </td>
  );
}
