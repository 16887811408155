import { useState, type ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { LightningOutlined } from 'qonto/react/assets/icons';
import type {
  LabelCategorizationCriteria,
  LabelCategorizationRule,
  LabelIdentifier,
} from 'qonto/react/models/label';
import styles from './styles.strict-module.css';

interface LabelCategorizationProps {
  labelIds: LabelIdentifier[];
  categorizationCriterias: LabelCategorizationCriteria[];
  hideDisclaimer?: boolean;
  onGenerateRules: (rules: LabelCategorizationRule[]) => void;
}

export function LabelCategorization({
  labelIds,
  categorizationCriterias,
  hideDisclaimer,
  onGenerateRules,
}: LabelCategorizationProps): ReactNode {
  const { t } = useIntl();
  const [initLabelIds, setInitLabelIds] = useState(labelIds);
  const [initCriterias, setInitCriterias] = useState(categorizationCriterias);
  const [listState, setListState] = useState(new Map<string, string | undefined>([]));

  const uniqueCounterparties = [
    ...new Set(
      categorizationCriterias.map(
        criteria => criteria.counterpartyName || criteria.rawCounterpartyName
      )
    ),
  ];
  const hasMultipleCounterparties = uniqueCounterparties.length > 1;
  const remainingCounterparties = uniqueCounterparties.slice(2);

  labelIds.forEach(({ labelListId, labelId }) => {
    listState.set(labelListId, labelId);
  });

  Array.from(listState).forEach(([key]) => {
    const isUndefined = !labelIds.find(({ labelListId }) => labelListId === key);
    if (isUndefined) listState.set(key, undefined);
  });

  const handleGenerateRules = (includePast: boolean): void => {
    const categorizationRules = Array.from(listState)
      .map(([labelListId, labelId]) => {
        return categorizationCriterias.map(matchingCriteria => ({
          labelListId,
          labelId,
          matchingCriteria,
          includePast,
        }));
      })
      .flat() as LabelCategorizationRule[];

    onGenerateRules(categorizationRules);
    setInitLabelIds(labelIds);
    setInitCriterias(categorizationCriterias);
    setListState(new Map([]));
  };

  const hasLabelChanges =
    JSON.stringify(initLabelIds) !== JSON.stringify(labelIds) ||
    JSON.stringify(initCriterias) !== JSON.stringify(categorizationCriterias);

  return (
    <AnimatePresence>
      {hasLabelChanges && !hideDisclaimer ? (
        <motion.div
          initial={{ opacity: 0, height: 0.8 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          <article className={styles['categorization-card']} data-testid="label-categorization">
            <h4 className={styles['categorization-card-title']} data-testid="title">
              <LightningOutlined className="mr-8" />
              {t('transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.title', {
                count: labelIds.length,
              })}
            </h4>
            {hasMultipleCounterparties ? (
              <p className="mb-16 body-2" data-testid="bulk-counterpartynames">
                <span>
                  {t(
                    'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.subtitle.bulk.message',
                    {
                      count: labelIds.length,
                      counterparty_name_one: uniqueCounterparties[0] ?? '',
                      counterparty_name_two: uniqueCounterparties[1] ?? '',
                    }
                  )}
                </span>
                {remainingCounterparties.length > 0 && (
                  <span data-testid="remaining-counterpartynames">
                    {' '}
                    {t(
                      'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.subtitle.bulk.counterparty-placeholder',
                      {
                        count: remainingCounterparties.length,
                      }
                    )}
                  </span>
                )}
                <span>.</span>
              </p>
            ) : (
              <p className="mb-16 body-2" data-testid="single-counterpartyname">
                {t(
                  'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.subtitle.single',
                  { count: labelIds.length, counterparty_name: uniqueCounterparties[0] ?? '' }
                )}
              </p>
            )}
            <footer className={styles['categorization-actions']}>
              <button
                type="button"
                className="btn btn--secondary btn--small"
                onClick={() => {
                  handleGenerateRules(true);
                }}
                data-testid="cta-past-future"
              >
                {t(
                  'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.button.past-future'
                )}
              </button>
              <button
                type="button"
                className="btn btn--primary btn--small"
                onClick={() => {
                  handleGenerateRules(false);
                }}
                data-testid="cta-future"
              >
                {t(
                  'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.button.future'
                )}
              </button>
            </footer>
          </article>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
