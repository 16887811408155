import type { ReactNode } from 'react';
import { type StatusProps } from 'design-system-kit';
import { CheckmarkRoundedOutlined, ProcessingOutlined } from 'qonto/react/assets/icons';
import type { StatusHistoryStatus, TransactionStatus } from 'qonto/react/constants';
import {
  Status as TransactionStatusEnum,
  StatusHistoryStatus as StatusHistoryStatusEnum,
} from 'qonto/react/graphql';

type StatusMap = Record<
  TransactionStatus,
  {
    badgeStatus: StatusProps['level'];
    title: string;
  }
>;

export const statusMap: StatusMap = {
  [TransactionStatusEnum.Completed]: {
    badgeStatus: 'validated',
    title: 'Completed',
  },
  [TransactionStatusEnum.Pending]: {
    badgeStatus: 'in-progress',
    title: 'Processing',
  },
  [TransactionStatusEnum.Declined]: {
    badgeStatus: 'error',
    title: 'Rejected',
  },
  [TransactionStatusEnum.Reversed]: {
    badgeStatus: 'error',
    title: 'Rejected',
  },
};

type StatusHistoryMap = Record<
  StatusHistoryStatus,
  {
    title: string;
    icon: ReactNode;
  }
>;

export const statusHistoryMap: StatusHistoryMap = {
  [StatusHistoryStatusEnum.Settled]: {
    title: 'Completed',
    icon: <CheckmarkRoundedOutlined />,
  },
  [StatusHistoryStatusEnum.Authorized]: {
    title: 'Processing',
    icon: <ProcessingOutlined />,
  },
};
