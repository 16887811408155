import React, { type ReactElement } from 'react';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface DateProps {
  date: string;
  omitTime?: boolean;
}

export function Date({ date, omitTime }: DateProps): ReactElement {
  const { locale } = useIntl();
  const token = omitTime ? 'date-year-s' : 'date-time-s';

  const formattedDate = dateToken({
    date,
    locale: locale as string,
    token,
  });

  return (
    <div className={cx(styles.wrapper)} data-test-date>
      {formattedDate}
    </div>
  );
}
