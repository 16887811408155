import { useState, type ReactNode } from 'react';
import { Button } from 'react-aria-components';
import cx from 'clsx';
import { Popover, PopoverHeader, PopoverSection } from 'qonto/react/components/table-v2/popover';
import type { Attachment } from 'qonto/react/graphql';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import type { DataWithIconProps } from 'qonto/react/components/table-v2/cells/data-with-icon-cell/data-with-icon-cell';
import { Document } from 'qonto/react/assets/icons';
import { AttachmentViewer } from '../../../attachment-viewer';
import styles from './styles.strict-module.css';

interface MultipleAttachmentsCellPopoverProps {
  attachments: Attachment[];
  title: string;
  icon: ReactNode;
  type: DataWithIconProps['type'];
}
export function MultipleAttachmentsCellPopover({
  attachments,
  title,
  icon,
  type,
}: MultipleAttachmentsCellPopoverProps): ReactNode {
  const [showPreview, setShowPreview] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);

  return (
    <Popover>
      <PopoverHeader>
        <DataWithIconCell title={title} icon={icon} type={type} />
      </PopoverHeader>
      <PopoverSection>
        <div className={cx(styles.container)}>
          {attachments.map(attachment => {
            const { id, file } = attachment;
            const fileName = file?.name ?? '';
            const name = fileName.length > 20 ? `⋅⋅ ${fileName.slice(-20)}` : fileName;

            return (
              <Button
                key={id}
                className={cx(styles['popover-item'])}
                onPress={() => {
                  setSelectedAttachment(attachment);
                  setShowPreview(true);
                }}
                data-testid="preview-trigger"
              >
                <DataWithIconCell title={name} icon={<Document />} />
              </Button>
            );
          })}
        </div>
      </PopoverSection>
      {selectedAttachment ? (
        <AttachmentViewer
          attachment={selectedAttachment}
          isOpen={showPreview}
          onOpenChange={setShowPreview}
        />
      ) : null}
    </Popover>
  );
}
