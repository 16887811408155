import cx from 'clsx';
import { type ReactNode } from 'react';
import { Comment, NotificationDot } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface CommentsCellProps {
  textSlot: ReactNode;
  iconSlot?: ReactNode;
  showNotificationDot?: boolean;
}

export function CommentsCellRoot({
  textSlot,
  iconSlot,
  showNotificationDot = false,
}: CommentsCellProps): ReactNode {
  return (
    <div className={cx(styles['cell-container'])}>
      <div data-testid="icon-content" className={cx(styles['icons-container'])}>
        {iconSlot ? (
          iconSlot
        ) : (
          <Comment aria-hidden className={cx(styles['comment-icon'])} data-testid="comment-icon" />
        )}
        {showNotificationDot ? (
          <NotificationDot
            aria-hidden
            className={cx(styles['status-icon'])}
            data-testid="notification-dot"
          />
        ) : null}
      </div>
      <div data-testid="text-content" className="body-2">
        {textSlot}
      </div>
    </div>
  );
}
