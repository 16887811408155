import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { ArrowLeftOutlined, CrossOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface HeaderProps {
  title: string;
  onClose: () => void;
  onBackButtonClick: () => void;
  isBackButtonVisible: boolean;
  bucketTitle: string;
}

export function Header({
  bucketTitle,
  onBackButtonClick,
  isBackButtonVisible,
  onClose,
  ...props
}: HeaderProps): ReactNode {
  const { t } = useIntl();
  return (
    <div className={cx(styles.header)} data-test-side-drawer-header {...props}>
      <button
        type="button"
        className={cx(
          styles['back-button'],
          isBackButtonVisible ? styles.visible : styles.hidden,
          'btn',
          'btn--icon-only',
          'btn--tertiary'
        )}
        aria-label={t('a11y.buttons.back-aria-label')}
        onClick={onBackButtonClick}
        data-test-back-button
        data-test-visible={isBackButtonVisible}
      >
        <ArrowLeftOutlined aria-hidden="true" />
      </button>
      <h1 className={cx(styles.title)} data-test-title>
        {bucketTitle || t('in-context-integrations.side-drawer.title')}
      </h1>
      <button
        type="button"
        className={cx(styles['close-button'], 'btn', 'btn--icon-only', 'btn--tertiary')}
        aria-label={t('a11y.buttons.close-aria-label')}
        onClick={onClose}
        data-test-close-button
      >
        <CrossOutlined aria-hidden="true" />
      </button>
    </div>
  );
}
