import { type ReactNode } from 'react';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface MileageTableCellProps {
  note: string;
}

export function MileageTableCell({ note }: MileageTableCellProps): ReactNode {
  const { t } = useIntl();

  return (
    <div className={cx(styles['cell-content'])} data-test-request-cell-mileage>
      <div
        className={cx(styles['request-mileage-icon'])}
        aria-hidden="true"
        data-test-request-cell-mileage-icon
      >
        <StaticThemedAsset assetPath="/icon/cards/request-mileage.svg" />
      </div>
      <div className={cx(styles['cell-info'])}>
        <p data-test-request-cell-mileage-title>{t('labels.mileage')}</p>
        <p className={cx(styles.subtitle, 'caption')} data-test-request-cell-mileage-subtitle>
          {note}
        </p>
      </div>
    </div>
  );
}
