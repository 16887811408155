import { type ReactNode } from 'react';
import { PlusSign } from 'qonto/react/assets/icons';
import { Label as LabelComponent } from 'qonto/react/components/label';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import type { LabelList } from 'qonto/react/models/label';
import type { Label } from 'qonto/react/graphql';
import { LabelCellPopover } from './popover';

interface LabelCellProps {
  label: Label | undefined;
  labelList: LabelList;
  transactionId: string;
  transactionLabels: Label[];
}
export function LabelCell({
  label,
  labelList,
  transactionId,
  transactionLabels,
}: LabelCellProps): ReactNode {
  return (
    <BaseCell
      data-testid="label-cell"
      popoverSlot={
        <LabelCellPopover
          label={label}
          labelList={labelList}
          transactionId={transactionId}
          transactionLabels={transactionLabels}
        />
      }
    >
      {label ? (
        <LabelComponent color={labelList.color}>{label.name}</LabelComponent>
      ) : (
        <DataWithIconCell title="Add label" icon={<PlusSign />} />
      )}
    </BaseCell>
  );
}
