import { type ReactNode } from 'react';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import type { PayableInvoice, ReceivableInvoice } from 'qonto/react/graphql';
import { Document, Documents, PlusSign } from 'qonto/react/assets/icons';

interface InvoiceCellProps {
  invoices: (ReceivableInvoice | PayableInvoice)[];
  side: string;
}

export function InvoiceCell({ invoices, side }: InvoiceCellProps): ReactNode {
  if (!invoices.length) {
    return (
      <BaseCell>
        <DataWithIconCell
          title={`${side === 'credit' ? 'Create client' : 'Upload supplier'} invoice`}
          icon={<PlusSign />}
        />
      </BaseCell>
    );
  }

  if (invoices.length === 1) {
    return (
      <BaseCell>
        <DataWithIconCell
          title={`${side === 'credit' ? 'Client' : 'Supplier'} invoice`}
          subtitle={invoices[0]?.invoiceNumber}
          icon={<Document />}
        />
      </BaseCell>
    );
  }

  return (
    <BaseCell>
      <DataWithIconCell
        title={`${invoices.length.toString()} ${
          side === 'credit' ? 'client' : 'supplier'
        } invoices`}
        icon={<Documents />}
      />
    </BaseCell>
  );
}
