import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { type ReactNode } from 'react';
import type { BalanceTableType } from './balance-table';

interface AttributeCellProps {
  type: BalanceTableType;
}

export function AttributeCell({ type }: AttributeCellProps): ReactNode {
  const { t } = useIntl();

  return (
    <span>
      {type === 'start'
        ? t('cash-flow.table.header-column.start-of-month')
        : t('cash-flow.table.header-column.end-of-month')}
    </span>
  );
}
