import {
  createContext,
  useCallback,
  useContext,
  useReducer,
  type Dispatch,
  type ReactNode,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import type { Transaction } from 'qonto/react/graphql';
import { useUpdateBulkActions } from '../hooks/use-update-bulk-actions';

interface ContextState {
  labels: {
    updatedLabels: Record<string, string>;
  };
  verificationStatus: {
    verificationStatus: Transaction['qualifiedForAccounting'] | null;
    verificationStatusChanged: boolean;
  };
  category: {
    category: Transaction['activityTag'] | null;
    categoryChanged: boolean;
  };
  organizationId: string;
}
interface LabelAction {
  field: 'labels';
  type: 'setUpdatedLabels';
  payload: [string, string];
}

interface VerificationStatusAction {
  field: 'verificationStatus';
  type: 'setVerificationStatus';
  payload: Transaction['qualifiedForAccounting'];
}

interface CategoryAction {
  field: 'category';
  type: 'setCategory';
  payload: Transaction['activityTag'];
}

interface BulkTransactionsContext {
  state: ContextState;
  dispatch: Dispatch<LabelAction | VerificationStatusAction | CategoryAction>;
}

const reducer = (
  state: ContextState,
  action: LabelAction | VerificationStatusAction | CategoryAction
): ContextState => {
  switch (action.field) {
    case 'labels':
      return {
        ...state,
        labels: {
          updatedLabels: {
            ...state.labels.updatedLabels,
            [action.payload[0]]: action.payload[1],
          },
        },
      };
    case 'verificationStatus':
      return {
        ...state,
        verificationStatus: {
          verificationStatus: action.payload,
          verificationStatusChanged: true,
        },
      };
    case 'category':
      return {
        ...state,
        category: {
          category: action.payload,
          categoryChanged: true,
        },
      };
    default:
      return state;
  }
};

const initialState: ContextState = {
  labels: { updatedLabels: {} },
  verificationStatus: {
    verificationStatus: false,
    verificationStatusChanged: false,
  },
  category: {
    category: null,
    categoryChanged: false,
  },
  organizationId: '',
};
const BulkTransactionsContext = createContext<BulkTransactionsContext | undefined>(undefined);

export function BulkTransactionsProvider({
  children,
  organizationId,
}: {
  children: ReactNode;
  organizationId: string;
}): ReactNode {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    organizationId,
  });

  return (
    <BulkTransactionsContext.Provider value={{ state, dispatch }}>
      {children}
    </BulkTransactionsContext.Provider>
  );
}

interface UseBulkTransactions {
  labels: {
    updatedLabels: Record<string, string>;
    aggregatedUpdatedLabels: string[];
    setUpdatedLabels: (labelListId: string, labelId: string) => void;
    mutationFn: (selectedTransactions: string[]) => void;
  };
  verificationStatus: {
    verificationStatus: Transaction['qualifiedForAccounting'] | null;
    verificationStatusChanged: boolean;
    setVerificationStatus: (status: Transaction['qualifiedForAccounting']) => void;
    mutationFn: (selectedTransactions: string[]) => void;
  };
  category: {
    category: Transaction['activityTag'] | null;
    categoryChanged: boolean;
    setCategory: (category: Transaction['activityTag']) => void;
    mutationFn: (selectedTransactions: string[]) => void;
  };
  isMutating: boolean;
}

const useBulkTransactions = (): UseBulkTransactions => {
  const context = useContext(BulkTransactionsContext);
  if (!context) {
    throw new Error('useBulkTransactions must be used within a BulkTransactionsContext');
  }
  const queryClient = useQueryClient();
  const { mutateBulkSidePanel, mutateVerificationStatus } = useUpdateBulkActions();
  const { state, dispatch } = context;
  const {
    labels: { updatedLabels },
    organizationId,
    verificationStatus: { verificationStatus, verificationStatusChanged },
    category: { category, categoryChanged },
  } = state;
  const aggregatedUpdatedLabels = Object.values(updatedLabels);

  const setUpdatedLabels = useCallback(
    (labelListId: string, labelId: string) => {
      dispatch({ type: 'setUpdatedLabels', payload: [labelListId, labelId], field: 'labels' });
    },
    [dispatch]
  );

  const setVerificationStatus = useCallback(
    (status: Transaction['qualifiedForAccounting']) => {
      dispatch({ type: 'setVerificationStatus', payload: status, field: 'verificationStatus' });
    },
    [dispatch]
  );

  const setCategory = useCallback(
    (newCategory: Transaction['activityTag']) => {
      dispatch({ type: 'setCategory', payload: newCategory, field: 'category' });
    },
    [dispatch]
  );

  const mutateBulkUpdates = useCallback(
    (selectedTransactions: string[]) => {
      mutateBulkSidePanel({
        organizationId,
        transactionIds: selectedTransactions,
        category: category ?? '',
        updatedLabelIds: aggregatedUpdatedLabels,
      });
    },
    [category, aggregatedUpdatedLabels, mutateBulkSidePanel, organizationId]
  );

  const mutateBulkVerificationStatus = useCallback(
    (selectedTransactions: string[]) => {
      mutateVerificationStatus({
        transactionIds: selectedTransactions as [string, ...string[]],
        qualifiedForAccounting: verificationStatus === null ? false : !verificationStatus,
        closePopover: () => {
          /* do nothing */
        },
      });
    },
    [mutateVerificationStatus, verificationStatus]
  );

  return {
    labels: {
      updatedLabels,
      aggregatedUpdatedLabels,
      setUpdatedLabels,
      mutationFn: mutateBulkUpdates,
    },
    verificationStatus: {
      verificationStatus,
      verificationStatusChanged,
      setVerificationStatus,
      mutationFn: mutateBulkVerificationStatus,
    },
    category: {
      category,
      categoryChanged,
      setCategory,
      mutationFn: mutateBulkUpdates,
    },
    isMutating: Boolean(queryClient.isMutating()),
  };
};

//  required for stubbing with sinon.js
export const bulkTransactionsManager = {
  useBulkTransactions,
};
