import { type ReactElement } from 'react';
import cx from 'clsx';
import { Tooltip } from 'design-system-kit';
import { CopyButton } from 'qonto/react/components/table-v2/buttons';
import { ActionSlot } from 'qonto/react/components/table-v2/action-slot';
import styles from './styles.strict-module.css';

interface MembershipItemProps {
  information: 'Email' | 'Role' | 'Team';
  value: string;
}

export function MembershipItem({
  information,
  value,
  ...props
}: MembershipItemProps): ReactElement {
  return (
    <div className={cx('overlay', styles.container)} data-testid="item" {...props}>
      <div className={styles.infos}>
        <span data-testid="information" className={cx('caption', styles.key)}>
          {information}
        </span>
        <span data-testid="value" className="body-2">
          {value}
        </span>
      </div>

      {information === 'Email' && (
        <ActionSlot size="28" className={styles.action}>
          <Tooltip
            delay={400}
            closeDelay={0}
            data-testid="tooltip-content"
            label="Copy informations"
          >
            <CopyButton text={value} />
          </Tooltip>
        </ActionSlot>
      )}
    </div>
  );
}
