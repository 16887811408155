import { type ReactNode } from 'react';
import { BadgeStatus } from 'design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type { Transaction } from 'qonto/react/graphql';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { useToggleVerificationStatus } from 'qonto/react/hooks/use-toggle-verification-status';
import { Status as TransactionStatusEnum } from 'qonto/react/graphql';
import { VerificationStatusCellPopover } from './popover';
import { getVerificationStatusInfo } from './util';

interface VerificationStatusCellProps {
  transaction: Transaction;
}

export function VerificationStatusCell({ transaction }: VerificationStatusCellProps): ReactNode {
  const qualifiedForAccounting = transaction.qualifiedForAccounting;

  const { mutate, isPending } = useToggleVerificationStatus();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { status } = transaction;

  const statusBadgeInfo = getVerificationStatusInfo(status, qualifiedForAccounting);
  const shouldEnablePopover = ![
    TransactionStatusEnum.Declined,
    TransactionStatusEnum.Reversed,
  ].includes(status);

  const toggleVerificationStatus = (closePopover: () => void): void => {
    mutate(
      {
        transactionIds: [transaction.id],
        qualifiedForAccounting,
        closePopover,
      },
      {
        onSuccess: () => {
          const toastMessage = qualifiedForAccounting
            ? 'The verification of the bookkeeping details has been canceled.'
            : 'The bookkeeping details have been marked as verified.';
          toastFlashMessages.toastSuccess(toastMessage);
        },
        onError: () => {
          toastFlashMessages.toastError('An error occurred. Please try again.');
        },
      }
    );
  };

  return (
    <BaseCell
      data-testid="verification-status-cell"
      popoverSlot={
        shouldEnablePopover ? (
          <VerificationStatusCellPopover
            status={status}
            qualifiedForAccounting={qualifiedForAccounting}
            toggleVerificationStatus={toggleVerificationStatus}
            isUpdatingStatus={isPending}
          />
        ) : null
      }
    >
      {statusBadgeInfo ? <BadgeStatus {...statusBadgeInfo} /> : '-'}
    </BaseCell>
  );
}
