import { type ReactNode } from 'react';
import { Avatar } from 'design-system-kit';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { CustomAvatar } from 'qonto/react/components/avatar';

interface TransactionCellProps {
  name: string;
  id: string;
  avatar?: string;
}

export function TransactionCell({ name, id, avatar }: TransactionCellProps): ReactNode {
  const subtitle = `⋅⋅ ${id.slice(-14).toUpperCase()}`;
  const icon = avatar ? (
    <Avatar alt={name} src={avatar} data-testid="avatar" />
  ) : (
    <CustomAvatar name={name} />
  );

  return (
    <BaseCell>
      <DataWithIconCell title={name} subtitle={subtitle} icon={icon} />
    </BaseCell>
  );
}
