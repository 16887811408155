import { useEffect, useRef, type HTMLProps, type ReactNode } from 'react';
import styles from './styles.strict-module.css';

interface CheckboxProps extends HTMLProps<HTMLInputElement> {
  indeterminate?: boolean;
}
export function Checkbox({ indeterminate, checked, ...props }: CheckboxProps): ReactNode {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current !== null) {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [indeterminate, checked]);

  return (
    <input type="checkbox" ref={ref} checked={checked} className={styles.checkbox} {...props} />
  );
}
