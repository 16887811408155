import { type ReactNode } from 'react';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import type { BalanceData } from '../../models/balance';

interface BalanceCellProps {
  balance: BalanceData;
}

export function BalanceCell({ balance }: BalanceCellProps): ReactNode {
  const { formatNumber } = useIntl();
  const { amount, isCurrentMonth, type } = balance;

  if (type === 'end' && isCurrentMonth) {
    return <span>-</span>;
  }

  const value = formatNumber(amount, {
    currency: 'EUR',
    style: 'currency',
  });
  return <span>{value}</span>;
}
