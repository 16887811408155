import { type ReactNode } from 'react';
import { Button } from 'react-aria-components';
import cx from 'clsx';
import { Popover, PopoverHeader, PopoverSection } from 'qonto/react/components/table-v2/popover';
import type { Attachment } from 'qonto/react/graphql';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import type { DataWithIconProps } from 'qonto/react/components/table-v2/cells/data-with-icon-cell/data-with-icon-cell';
import { DocumentDownload, EyeOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface AttachmentCellPopoverProps {
  attachment: Attachment;
  title: string;
  icon: ReactNode;
  type: DataWithIconProps['type'];
  openPreview: () => void;
}
export function SingleAttachmentCellPopover({
  attachment,
  title,
  icon,
  type,
  openPreview,
}: AttachmentCellPopoverProps): ReactNode {
  const handleDownload = (): void => {
    window.open(attachment.downloadUrl, '_blank');
  };
  return (
    <Popover>
      <PopoverHeader>
        <DataWithIconCell title={title} icon={icon} type={type} />
      </PopoverHeader>
      <PopoverSection>
        <div className={cx(styles.container)}>
          <Button
            className={cx(styles['popover-item'])}
            onPress={() => {
              openPreview();
            }}
            data-testid="preview-trigger"
          >
            <DataWithIconCell title="View" icon={<EyeOutlined />} />
          </Button>
          <Button
            className={cx(styles['popover-item'])}
            onPress={handleDownload}
            data-testid="download-trigger"
          >
            <DataWithIconCell title="Download" icon={<DocumentDownload />} />
          </Button>
        </div>
      </PopoverSection>
    </Popover>
  );
}
