import { type ReactElement } from 'react';
import cx from 'clsx';
import {
  Button,
  Input,
  Label,
  ListBox,
  Popover,
  Select,
  SelectValue,
  type SelectProps,
} from 'react-aria-components';
import { ChevronBottomOutlined, SearchOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface DropdownProps<T extends object> extends Omit<SelectProps<T>, 'children'> {
  items: Iterable<T>;
  children: React.ReactNode | ((item: T) => React.ReactNode);
  filter?: (value: string) => void;
  label: string;
}

export function Dropdown<T extends object>({
  children,
  items,
  filter,
  label,
  ...props
}: DropdownProps<T>): ReactElement {
  return (
    <Select className={styles.select} {...props} data-testid="dropdown">
      <Label className={cx(styles.title)} data-testid="title">
        {label}
      </Label>
      <Button className={cx('body-1', 'overlay', styles.trigger)} data-testid="trigger">
        <SelectValue />
        <ChevronBottomOutlined aria-hidden="true" className={styles['arrow-icon']} />
      </Button>
      <Popover className={styles.popover}>
        {filter ? (
          <form className={cx(styles.search)} role="search">
            <SearchOutlined aria-hidden="true" />
            <Input
              placeholder="Search"
              onChange={e => {
                filter(e.target.value);
              }}
              className={styles.input}
              data-testid="search-input"
            />
          </form>
        ) : null}
        <ListBox items={items}>{children}</ListBox>
      </Popover>
    </Select>
  );
}
