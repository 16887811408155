import { type ReactNode } from 'react';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import type { Transaction } from 'qonto/react/graphql';
import { MembershipCellPopover } from './popover/membership-cell-popover';
import { Member } from './member/member';

interface MemberCellProps {
  transaction: Transaction;
}

export function MembershipCell({ transaction }: MemberCellProps): ReactNode {
  const membership = transaction.initiator;

  if (!membership) {
    return (
      <BaseCell>
        <span data-testid="empty">-</span>
      </BaseCell>
    );
  }

  return (
    <BaseCell popoverSlot={<MembershipCellPopover membership={membership} />}>
      <Member fullName={membership.fullName} avatarUrl={membership.avatar.url} />
    </BaseCell>
  );
}
