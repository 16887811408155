import { type ReactElement } from 'react';
import styles from './styles.strict-module.css';

interface MemberProps {
  fullName: string;
  avatarUrl: string;
  'data-testid'?: string;
}

export function Member({ fullName, avatarUrl }: MemberProps): ReactElement {
  return (
    <div className={styles.container}>
      <img data-testid="avatar" src={avatarUrl} alt={fullName} className={styles['avatar-img']} />
      <span className="body-2" data-testid="name">
        {fullName}
      </span>
    </div>
  );
}
