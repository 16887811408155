import { useEffect, type ReactNode } from 'react';
import ENV from 'qonto/config/environment';

interface ZendeskSsoFormProps {
  jwt: string;
  returnTo: string;
}

const isTesting = (ENV as { environment: string }).environment === 'test';

export function ZendeskSsoForm({ jwt, returnTo }: ZendeskSsoFormProps): ReactNode {
  useEffect(() => {
    if (!isTesting) {
      const form = document.getElementById('zendesk-sso-jwt-form') as HTMLFormElement;
      form.submit();
    }
  }, []);

  return (
    <form id="zendesk-sso-jwt-form" action="https://qonto9015.zendesk.com/access/jwt" method="post">
      <input type="hidden" name="jwt" value={jwt} data-test-jwt />
      <input type="hidden" name="return_to" value={returnTo} data-test-return-to />
    </form>
  );
}
