import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { type TransactionModel } from 'qonto/react/models/transaction';
import styles from './styles.strict-module.css';

interface VatAccountingStatusProps {
  model: TransactionModel;
}

export function VatAccountingStatus({ model }: VatAccountingStatusProps): ReactNode {
  const { t, formatNumber } = useIntl();

  const { vatRate } = model;

  let formattedSuggestedRate = t('transactions.sidebar.vat.accounting.other');
  if (vatRate && vatRate > 0) {
    formattedSuggestedRate = `${formatNumber(vatRate)} %`;
  } else if (vatRate === 0) {
    formattedSuggestedRate = t('transactions.sidebar.vat.accounting.no-rate');
  }

  const handleEditVatSuggested = (): void => {
    model.vatStatus = 'vat_overriden';
    model.vatAmount = null;
    model.vatRate = null;
    model.save();
  };

  const handleConfirmVatSuggested = (): void => {
    model.vatStatus = 'vat_confirmed';
    model.save();
  };

  return (
    <>
      {model.vatStatus === 'vat_not_found' && (
        <div className={cx(styles.statusDescription)} data-test-vat-not-found>
          {t('transactions.sidebar.vat.not_found_desc')}
        </div>
      )}
      {model.vatStatus === 'vat_suggested' && (
        <>
          <div>
            <div className={cx(styles.amount)}>
              {formatNumber(model.vatAmount ?? 0, { style: 'currency', currency: 'EUR' })}

              <div className={cx(styles.amountRate)} data-test-vat-rate>
                ({formattedSuggestedRate})
              </div>
            </div>
          </div>

          <div className={cx(styles.vatSuggestedDescription)}>
            {t('transactions.sidebar.vat.suggested_desc')}
          </div>

          <div className={cx(styles.actions)}>
            <div className={cx(styles.actionsLeft)}>
              <button
                type="button"
                className="btn btn--secondary btn--stretch"
                onClick={handleEditVatSuggested}
                data-test-edit-suggested-vat-button
              >
                {t('btn.edit')}
              </button>
            </div>

            <div className={cx(styles.actionsRight)}>
              <button
                type="button"
                className="btn btn--primary btn--stretch"
                onClick={handleConfirmVatSuggested}
                data-test-confirm-suggested-vat-button
              >
                {t('btn.confirm')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
