import { type ReactNode } from 'react';
import cx from 'clsx';
import { Button, TooltipTrigger } from 'react-aria-components';
import type { Participant } from 'qonto/react/graphql';
import { Tooltip } from 'qonto/react/components/tooltip';
import styles from './styles.strict-module.css';

interface AvatarGroupProps {
  participants: Participant[];
  shouldShowTooltip: boolean;
}

function AvatarGroup({ participants, shouldShowTooltip }: AvatarGroupProps): ReactNode {
  return (
    <u className={cx(styles['avatar-group'])} data-testid="avatar-group">
      {participants.map(({ membership, id }) => (
        <li className={cx(styles['avatar-item'])} key={id}>
          {shouldShowTooltip ? (
            <TooltipTrigger delay={0} closeDelay={0}>
              <Button data-testid="tooltip-trigger" className={cx(styles['tooltip-trigger'])}>
                <img
                  data-testid="participant-avatar"
                  src={membership.avatar.url}
                  alt={membership.fullName}
                  className={cx(styles['avatar-img'])}
                />
              </Button>
              <Tooltip data-testid="tooltip-content">{membership.fullName}</Tooltip>
            </TooltipTrigger>
          ) : (
            <img
              data-testid="participant-avatar"
              src={membership.avatar.url}
              alt={membership.fullName}
              className={cx(styles['avatar-img'])}
            />
          )}
        </li>
      ))}
    </u>
  );
}

function NameLists({ participants }: { participants: Participant[] }): ReactNode {
  return (
    <ul className={cx(styles['names-list'])} data-testid="names-list">
      {participants.map(({ id, membership }, index) => (
        <li className={cx(styles['name-item'])} key={id}>
          {participants.length <= 3 ? membership.fullName : membership.firstName}
          {index < participants.length - 1 ? ', ' : ''}
        </li>
      ))}
    </ul>
  );
}

export function ParticipantsList({ participants }: { participants: Participant[] }): ReactNode {
  const shouldDisplayMoreCount = participants.length >= 20;
  const shouldShowTooltip = participants.length >= 3;
  const moreCount = shouldDisplayMoreCount ? (
    <span className="caption" data-testid="more-count">
      + {participants.length - 20}
    </span>
  ) : null;

  return (
    <div className={cx(styles['participants-list'])} data-testid="participants-list">
      <div data-testid="caption" className={cx(styles.text, 'caption')}>
        To
      </div>
      <AvatarGroup participants={participants} shouldShowTooltip={shouldShowTooltip} />
      {participants.length <= 6 ? <NameLists participants={participants} /> : moreCount}
    </div>
  );
}
