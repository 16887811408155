import React, { type ComponentPropsWithRef, type ReactNode } from 'react';

export function SearchOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M1.5 5.248a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Zm9.221 6.525a.75.75 0 0 0 1.225-.245.751.751 0 0 0-.167-.827l-2.31-2.31a5.273 5.273 0 1 0-1.065 1.065l2.317 2.317Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="transparent" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
