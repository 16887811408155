/* eslint-disable camelcase -- API model */
import { useQuery } from '@tanstack/react-query';
import type { Membership } from '../models/membership';
import type { Membership as MembershipApiResponse } from '../api/models';
import { useFetchApi } from './use-fetch-api';

export interface MembershipQueryResult {
  data: Membership | undefined;
  isPending: boolean;
  isError: boolean;
}

export async function fetchMembership(
  membershipId: string,
  fetchApi: ReturnType<typeof useFetchApi>
): Promise<Membership | undefined> {
  const response = await fetchApi(`v3/memberships/${membershipId}`);
  if (!response.ok) {
    throw new Error(`Error fetching membership: ${membershipId}`);
  }
  const data = (await response.json()) as MembershipApiResponse;
  const { id, full_name, first_name, last_name, avatar, email, role } = data.membership;

  return {
    id,
    fullName: full_name,
    firstName: first_name,
    lastName: last_name,
    avatar: {
      fileUrl: avatar.file_url,
    },
    email,
    role,
  };
}

export function useFetchMembership(membershipId: string): MembershipQueryResult {
  const fetchApi = useFetchApi();

  const {
    data,
    isLoading: isPending,
    isError,
  } = useQuery({
    queryKey: ['membership', membershipId],
    queryFn: () => fetchMembership(membershipId, fetchApi),
    staleTime: 1000 * 60 * 30, // 30 min
  });

  return {
    data,
    isPending,
    isError,
  };
}
