import { type ReactNode } from 'react';
import * as Sentry from '@sentry/ember';
import { useIntl, useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import ENV from 'qonto/config/environment';
import { registerJsURL } from 'qonto/constants/hosts';
import { PlusOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface NavigationDropdownFooterProps {
  canAddOrganization?: boolean;
}

export function NavigationDropdownFooter({
  canAddOrganization,
}: NavigationDropdownFooterProps): ReactNode {
  const registerLink = `${registerJsURL}/signup/select-country`;
  const { t } = useIntl();
  const segment = useEmberService('segment');
  const sessionManager = useEmberService('sessionManager');

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- ENV has a type but it's not reflected here
    if (ENV.environment === 'test') {
      e.preventDefault();
    }
    segment.track('add_organization');
  };

  const handleSignOut = (): void => {
    segment.track('logout');
    Sentry.getCurrentScope().setUser(null);
    void sessionManager.invalidate();
  };
  return (
    <div className={cx(styles.footer)}>
      <span>{canAddOrganization}</span>
      {canAddOrganization ? (
        <a
          href={registerLink}
          className={cx(styles['footer-item'], styles['add-organization'], styles['body-link'])}
          onClick={handleLinkClick}
          data-test-add-another-organization
        >
          <PlusOutlined width="12" height="12" />
          <span className={cx(styles['item-link'])}>
            {t('navigation.dropdown.add-organization')}
          </span>
        </a>
      ) : null}
      <button
        type="button"
        className={cx(styles['footer-item'])}
        onClick={handleSignOut}
        data-test-signout-button
      >
        {t('btn.sign_out')}
      </button>
    </div>
  );
}
