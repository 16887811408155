import { type ReactNode } from 'react';
import { Avatar } from 'design-system-kit';
import cx from 'clsx';
import type { Transaction, Card, Transfer, Income } from 'qonto/react/graphql';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { CustomAvatar } from 'qonto/react/components/avatar';
import { Side, SubjectType } from 'qonto/react/graphql';
import styles from './styles.strict-module.css';

const qontoLogo = 'https://qonto.com/customized-qonto-brand-logos/small.png';

interface CellProps {
  transaction: Transaction;
}

type Subject = Card | Income | Transfer;

function isCardSubject(
  subject: Subject | undefined,
  subjectType: SubjectType | null | undefined
): subject is Card {
  return subjectType === SubjectType.Card;
}

function isIncomeSubject(
  subject: Subject | undefined,
  subjectType: SubjectType | null | undefined
): subject is Income {
  return subjectType === SubjectType.Income;
}

function isTransferSubject(
  subject: Subject | undefined,
  subjectType: SubjectType | null | undefined
): subject is Transfer {
  return subjectType === SubjectType.Transfer;
}

function getTransactionSubject({
  card,
  income,
  transfer,
  subjectType,
}: Transaction): Subject | undefined | null {
  switch (subjectType) {
    case SubjectType.Card:
      return card;
    case SubjectType.Income:
      return income;
    case SubjectType.Transfer:
      return transfer;
    default:
      return undefined;
  }
}

export function SourceCell({ transaction }: CellProps): ReactNode {
  const { bankAccount, rawCounterpartyName, subjectType, side, enrichmentData } = transaction;
  const isBankAccount = side === Side.Debit;
  const subject = getTransactionSubject(transaction);

  let name;
  let subTitle;

  if (isBankAccount) {
    // Handle edge case where the current user may not have read access to the bank account
    if (bankAccount) {
      name = bankAccount.name;
      subTitle = displayIban(bankAccount.iban);
    }
  } else if (subject && isIncomeSubject(subject, subjectType)) {
    name = subject.name;
    subTitle = displayIban(subject.iban);
  } else if (subject && isCardSubject(subject, subjectType)) {
    name = rawCounterpartyName ?? '';
  }

  return (
    <BaseCell>
      {name ? (
        <DataWithIconCell
          title={name}
          subtitle={subTitle}
          icon={getCellIcon(name, isBankAccount, enrichmentData.logo.small)}
        />
      ) : (
        <span className={cx(styles['not-available'])}>Not available</span>
      )}
    </BaseCell>
  );
}

export function TargetCell({ transaction }: CellProps): ReactNode {
  const { bankAccount, rawCounterpartyName, subjectType, side, enrichmentData } = transaction;
  const isBankAccount = side === Side.Credit;
  const subject = getTransactionSubject(transaction);

  let name;
  let subTitle;

  if (isBankAccount) {
    // Handle edge case where the current user may not have read access to the bank account
    if (bankAccount) {
      name = bankAccount.name;
      subTitle = displayIban(bankAccount.iban);
    }
  } else if (subject && isTransferSubject(subject, subjectType)) {
    name = subject.beneficiary?.name ?? '';
    subTitle = displayIban(subject.beneficiary?.iban ?? '');
  } else if (subject && isCardSubject(subject, subjectType)) {
    name = rawCounterpartyName;
  }

  return (
    <BaseCell>
      {name ? (
        <DataWithIconCell
          title={name}
          subtitle={subTitle}
          icon={getCellIcon(name, isBankAccount, enrichmentData.logo.small)}
        />
      ) : (
        <span className={cx(styles['not-available'])}>Not available</span>
      )}
    </BaseCell>
  );
}

function getCellIcon(name: string, isBankAccount: boolean, enrichedAvatar?: string): ReactNode {
  const bankAccountIcon = <Avatar alt={name} src={qontoLogo} data-testid="avatar" size="24" />;
  const counterPartyIcon = enrichedAvatar ? (
    <Avatar alt={name} src={enrichedAvatar} data-testid="avatar" size="24" />
  ) : (
    <CustomAvatar name={name} small size="24" />
  );

  return isBankAccount ? bankAccountIcon : counterPartyIcon;
}

function displayIban(iban: string): string {
  return `⋅⋅ ${iban.slice(-14).toUpperCase()}`;
}
