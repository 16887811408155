import { type ReactNode } from 'react';
import { BaseCell } from '../base-cell';
import { CopyButton } from '../../buttons';

interface CaptionCellProps {
  caption?: string | null;
}

export function CaptionCell({ caption }: CaptionCellProps): ReactNode {
  return (
    <BaseCell actionSlot={caption ? <CopyButton text={caption} /> : null}>
      <span data-testid="caption-cell" className="caption">
        {caption ? caption : '-'}
      </span>
    </BaseCell>
  );
}
