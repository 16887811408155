import { type Dispatch, type SetStateAction, useState, type ReactNode } from 'react';
import cx from 'clsx';
import { Dialog, Modal } from 'react-aria-components';
import { type Attachment } from 'qonto/react/graphql';
import { CrossOutlined, DownloadOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

export function AttachmentViewer({
  attachment,
  isOpen,
  onOpenChange,
}: {
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  attachment: Attachment;
  isOpen: boolean;
}): ReactNode {
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);

  if (!attachment.file) return null;

  const { name, url, contentType } = attachment.file;

  const isPdf = contentType === 'application/pdf';
  const isImage = contentType.startsWith('image/');
  const handleDownload = (): void => {
    window.open(attachment.downloadUrl, '_blank');
  };

  return (
    <Modal
      isDismissable
      className={cx(styles.modal)}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      data-testid="attachment-viewer-modal"
    >
      <Dialog>
        <header className={cx(styles.header)}>
          <div data-testid="description">Viewing attachment: {name}</div>
          <div>
            <button
              className="btn btn--icon-only btn--tertiary btn--large"
              aria-label="Download attachment"
              onClick={() => {
                handleDownload();
              }}
              type="button"
              data-testid="download-attachment"
            >
              <DownloadOutlined />
            </button>
            <button
              className="btn btn--icon-only btn--tertiary btn--large"
              aria-label="Close attachment viewer"
              onClick={() => {
                onOpenChange(false);
              }}
              type="button"
              data-testid="close-attachment-viewer"
            >
              <CrossOutlined />
            </button>
          </div>
        </header>
        {isPdf ? (
          <iframe
            title="pdf-preview-transaction-attachment"
            src={url}
            className={cx(styles['pdf-preview'], { hidden: !isIFrameLoaded })}
            onLoad={() => {
              setIsIFrameLoaded(true);
            }}
            data-testid="pdf-preview-attachment-iframe"
          />
        ) : null}
        {isImage ? <ImageViewer attachment={attachment} /> : null}
      </Dialog>
    </Modal>
  );
}

function ImageViewer({ attachment }: { attachment: Attachment }): ReactNode {
  const [isLoaded, setIsLoaded] = useState(false);
  if (!attachment.file) return null;

  const { name, url } = attachment.file;

  return (
    <div className={cx(styles['image-container'])}>
      <img
        src={url}
        alt={name}
        onLoad={() => {
          setIsLoaded(true);
        }}
        className={cx(styles['image-preview'], { hidden: !isLoaded })}
        data-testid="image-preview-attachment"
      />
    </div>
  );
}
