import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { SddOneOff, SddRecurring } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

type SDDCollectionType = 'one-off' | 'recurring';

interface TypeCardProps {
  type: SDDCollectionType;
  onClick: (type: SDDCollectionType) => void;
}

export function TypeCard({ type, onClick }: TypeCardProps): ReactNode {
  const { t } = useIntl();

  const TypeIcon = type === 'one-off' ? SddOneOff : SddRecurring;

  return (
    <button
      type="button"
      className={cx(styles['type-card'])}
      data-test-type-card={type}
      onClick={() => {
        onClick(type);
      }}
    >
      <TypeIcon className={cx(styles['type-icon'])} data-test-type-icon={type} />

      <header className="title-3 mb-8" data-test-type-card-title>
        {t(`sdd-collections.creation-flow.type-selection.${type}.title`)}
      </header>

      <span className="body-2" data-test-type-card-subtitle>
        {t(`sdd-collections.creation-flow.type-selection.${type}.description`)}
      </span>
    </button>
  );
}
