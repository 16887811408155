import { useState, type ReactNode } from 'react';
import cx from 'clsx';
import colorSelector from 'qonto/utils/color-selector';
import styles from './styles.strict-module.css';

interface AvatarProps {
  avatar: string;
  className?: string;
  name?: string;
}

export function Avatar({ avatar, className, name, ...rest }: AvatarProps): ReactNode {
  const [displayingAvatar, setDisplayingAvatar] = useState(true);

  const firstLetter = name?.charAt(0);
  const backgroundColor = colorSelector(name);

  return (
    <div className={cx(styles.container, className)} {...rest}>
      {displayingAvatar ? (
        <img
          className={cx(styles.avatar)}
          src={avatar}
          alt=""
          onError={() => {
            setDisplayingAvatar(false);
          }}
          data-test-avatar-image
        />
      ) : (
        <div className={cx(styles.letter)} style={{ backgroundColor }}>
          <p data-test-avatar-letter>{firstLetter}</p>
        </div>
      )}
    </div>
  );
}
