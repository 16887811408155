import { type ReactNode } from 'react';

export function PlusSign(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 8h4m0 0h4M8 8V4m0 4v4"
      />
    </svg>
  );
}
