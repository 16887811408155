import type { ReactElement } from 'react';
import { ProductBadge } from '@repo/domain-kit';
import React from 'react';
import { clsx } from 'clsx';
import styles from './styles.strict-module.css';

interface MiniAddonCardProps {
  name: string;
  badgeTitle: string;
  billingRecurrence: string;
  nextBillingDate: string;
}

interface Addons {
  addons: MiniAddonCardProps[];
  isLoading: boolean;
}

function MiniAddonCard({
  name,
  badgeTitle,
  billingRecurrence,
  nextBillingDate,
}: MiniAddonCardProps): React.JSX.Element {
  return (
    <div className={styles.container}>
      <div className="mb-8">
        <div className="mb-8">
          <ProductBadge traits={[{ type: 'active', title: badgeTitle }]} />
        </div>

        <h3 className="body-1" data-test-addon-name>
          {name}
        </h3>
      </div>

      <div>
        <div className="mb-12">
          <div data-test-billing-recurrence className={clsx(styles['color-secondary'], 'caption')}>
            {billingRecurrence}
          </div>
          <div data-test-next-billing-date className={clsx(styles['color-secondary'], 'caption')}>
            {nextBillingDate}
          </div>
        </div>
      </div>
    </div>
  );
}

export function HeaderAddonsOverview({ addons, isLoading }: Addons): ReactElement {
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div className={styles.scroll}>
      {addons.map(addon => (
        <div data-test-addon-card={addon.name} key={addon.name}>
          <MiniAddonCard
            name={addon.name}
            badgeTitle={addon.badgeTitle}
            billingRecurrence={addon.billingRecurrence}
            nextBillingDate={addon.nextBillingDate}
          />
        </div>
      ))}
    </div>
  );
}
