import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useMutation, useQueryClient, type UseMutateFunction } from '@tanstack/react-query';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import { useToggleVerificationStatus } from './use-toggle-verification-status';

interface MutationPayload {
  updatedLabelIds: string[];
  transactionIds: string[];
  organizationId: string;
  category: string;
}

export const useUpdateBulkActions = (): {
  mutateBulkSidePanel: UseMutateFunction<void, Error, MutationPayload>;
  mutateVerificationStatus: ReturnType<typeof useToggleVerificationStatus>['mutate'];
} => {
  const queryClient = useQueryClient();
  const fetchApi = useFetchApi();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { mutate: mutateVerificationStatus } = useToggleVerificationStatus();

  const submitBulkActions = async (payload: MutationPayload): Promise<void> => {
    try {
      const response = await fetchApi(`v1/bulk_actions`, {
        method: 'POST',
        body: JSON.stringify({
          bulk_action: {
            class: 'transaction',
            organization_id: payload.organizationId,
            ids: payload.transactionIds,
            fields: {
              label_ids: payload.updatedLabelIds,
              activity_tag: payload.category,
            },
          },
        }),
      });
      if (!response.ok) throw Error('Your changes could not be saved');
    } catch (e) {
      throw new Error('Your changes could not be saved');
    }
  };

  const { mutate: mutateBulkSidePanel } = useMutation({
    mutationFn: submitBulkActions,
    onSuccess: async (_, variables) => {
      const numberOfTransactions = variables.transactionIds.length;

      await queryClient.invalidateQueries({ queryKey: ['search-transactions-graphql'] });
      toastFlashMessages.toastInfo(
        `Your changes have been applied to ${numberOfTransactions.toString()} transactions`
      );
    },
    onError: () => {
      toastFlashMessages.toastError('Your changes could not be saved');
    },
  });

  return { mutateBulkSidePanel, mutateVerificationStatus };
};
