import { type Key, type ReactElement, useEffect, useState } from 'react';
import cx from 'clsx';
import { ListBoxItem } from 'react-aria-components';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { bulkCategoryManager } from 'qonto/react/hooks/use-bulk-category';
import type { TransactionCategory } from 'qonto/react/constants';
import { bulkTransactionsManager } from 'qonto/react/contexts/bulk-transactions-context';
import { Dropdown } from 'qonto/react/components/table-v2/dropdown';
import { useCategoryFilter } from '../../../../cells/category-cell/category-map';
import styles from './styles.strict-module.css';

export function CategorySection(): ReactElement {
  const { filteredList, filter, resetFilter } = useCategoryFilter();
  const { category: initialCategory } = bulkCategoryManager.useBulkCategory();
  const {
    category: { setCategory: bulkCategoryUpdaterFn },
  } = bulkTransactionsManager.useBulkTransactions();

  const [selectedCategory, setSelectedCategory] = useState(initialCategory);

  useEffect(() => {
    setSelectedCategory(initialCategory);
  }, [initialCategory]);

  const handleSelectionChange = (key: Key): void => {
    setSelectedCategory(key as TransactionCategory);
    bulkCategoryUpdaterFn(key as TransactionCategory);
  };

  return (
    <section className={cx(styles.section)} data-testid="category-section">
      <Dropdown
        placeholder="Select a category"
        label="Category"
        items={filteredList}
        filter={filter}
        selectedKey={selectedCategory}
        onSelectionChange={handleSelectionChange}
        onOpenChange={resetFilter}
      >
        {item => (
          <ListBoxItem key={item.id} textValue={item.title} className={styles['list-item']}>
            <DataWithIconCell title={item.title} icon={item.icon} />
          </ListBoxItem>
        )}
      </Dropdown>
    </section>
  );
}
