import { type ReactNode } from 'react';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function Header(): ReactNode {
  const { t } = useIntl();

  return (
    <>
      <th scope="col" className={cx(styles['header-cell'], styles.empty)} aria-hidden="true" />
      <th scope="col" className={cx(styles['header-cell'])} data-test-table-header-statement>
        {t('statements.list.columns.statement')}
      </th>
      <th scope="col" className={cx(styles['header-cell'])} data-test-table-header-date>
        {t('statements.list.columns.date')}
      </th>
      <th scope="col" className={cx(styles['header-cell'])} data-test-table-header-account>
        {t('statements.list.columns.account')}
      </th>
      <th scope="col" className={cx(styles['header-cell'])} data-test-table-header-limit />
      <th scope="col" className={cx(styles['header-cell'], styles.empty)} aria-hidden="true" />
    </>
  );
}
