import type { StatusProps } from 'design-system-kit';
import type { Transaction } from 'qonto/react/graphql';
import { Status as TransactionStatusEnum } from 'qonto/react/graphql';

export function getVerificationStatusInfo(
  status: Transaction['status'],
  qualifiedForAccounting: boolean
): {
  level: StatusProps['level'];
  text: string;
} | null {
  if ([TransactionStatusEnum.Declined, TransactionStatusEnum.Reversed].includes(status)) {
    return null;
  }

  if (qualifiedForAccounting) {
    return { level: 'validated', text: 'Verified' };
  }

  return { level: 'waiting', text: 'To verify' };
}
