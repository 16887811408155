import type { ReactNode } from 'react';
import {
  Tooltip as ReactAriaTooltip,
  type TooltipProps as ReactAriaTooltipProps,
} from 'react-aria-components';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface TooltipProps extends Omit<ReactAriaTooltipProps, 'children'> {
  children: ReactNode;
}

export function Tooltip({ children, ...props }: TooltipProps): ReactNode {
  return (
    <ReactAriaTooltip {...props} offset={6} className={cx(styles.tooltip)}>
      {children}
    </ReactAriaTooltip>
  );
}
