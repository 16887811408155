import type { ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

export function VirtualCard(): ReactNode {
  const { t } = useIntl();

  return (
    <div className={`body-1 ${cx(styles['cell-content'])}`} data-test-request-cell-virtual-card>
      <StaticThemedAsset
        assetPath="/illustrations/cards/card-virtual.svg"
        className={styles['card-icon']}
        data-test-request-cell-virtual-card-icon
      />
      {t('cards.fullname.virtual')}
    </div>
  );
}
