import { type ReactNode } from 'react';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { VAT_STATUS } from 'qonto/constants/vat';
import styles from './styles.strict-module.css';

export type ValueOf<T> = T[keyof T];

type VatStatusType = ValueOf<typeof VAT_STATUS>;

interface EmptyStateProps {
  vat?: {
    status: VatStatusType;
  };
}

export function EmptyState({ vat }: EmptyStateProps): ReactNode {
  const { t } = useIntl();

  const emptyStateMessage =
    vat?.status === VAT_STATUS.undetected
      ? t('transactions.sidebar.bookkeeping.vat-not-detected')
      : t('transactions.sidebar.bookkeeping.no-vat');

  return (
    <div data-testid="multi-vat-empty">
      <p className={styles['empty-state-message']}>{emptyStateMessage}</p>
    </div>
  );
}
