import type { ReactNode } from 'react';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import type { Transaction } from 'qonto/react/graphql';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { OperationMethod } from 'qonto/react/graphql';

interface PaymentMethodCellProps {
  transaction: Transaction;
}

export function PaymentMethodCell({
  transaction: { operationMethod },
}: PaymentMethodCellProps): ReactNode {
  const { t } = useIntl();

  const labelForOperationMethod = {
    [OperationMethod.Biller]: t('transactions.operation-types.biller'),
    [OperationMethod.Card]: t('transactions.operation-types.card'),
    [OperationMethod.Cheque]: t('transactions.operation-types.cheque'),
    [OperationMethod.DirectDebit]: t('transactions.operation-types.direct-debit'),
    [OperationMethod.DirectDebitHold]: t('transactions.operation-types.direct-debit-hold'),
    [OperationMethod.PagopaPayment]: t('transactions.operation-types.pagopa-payment'),
    [OperationMethod.Tax]: t('transactions.operation-types.tax'),
    [OperationMethod.Transfer]: t('transactions.operation-types.transfer'),
    [OperationMethod.PayLater]: t('transactions.operation-types.pay-later'),
    [OperationMethod.Other]: t('transactions.operation-types.unknown'),
  };

  return <BaseCell>{labelForOperationMethod[operationMethod]}</BaseCell>;
}
