import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import { type ReactNode } from 'react';
import cx from 'clsx';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { STEPS } from 'qonto/constants/international-out/lifecycle';
import styles from './styles.strict-module.css';

interface ProcessingEvent {
  name: 'creation' | 'validation' | 'shipment' | 'completion';
  date: string | null;
  order: number;
  status: 'completed' | 'in_progress' | 'awaiting';
}

interface EventProps {
  event: Pick<ProcessingEvent, 'name' | 'date'>;
}

export function Event({ event: { name, date } }: EventProps): ReactNode {
  const { locale, t } = useIntl();

  const getDescription = (): string | null => {
    switch (name) {
      case STEPS.CREATION:
        return t('international-out.timeline.step.creation');
      case STEPS.VALIDATION:
        return t('international-out.timeline.step.validation');
      case STEPS.SHIPMENT:
        return t('international-out.timeline.step.shipment');
      case STEPS.COMPLETION:
        return t('international-out.timeline.step.completion');
      default:
        return null;
    }
  };

  const description = getDescription();

  return (
    <div className={cx(styles.step)} data-testid="event">
      {date && typeof locale === 'string' ? (
        <p className={cx('caption', styles.caption)} data-testid="date">
          {dateToken({
            date,
            locale,
            token: 'date-time-s',
          })}
        </p>
      ) : null}
      {description ? (
        <p className={cx('body-2', styles.description)} data-testid="description">
          {description}
        </p>
      ) : null}
    </div>
  );
}
