import { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface ObfuscatedIbanProps {
  iban?: string;
}

const DOTS = [1, 2, 3, 4];

export function ObfuscatedIban({ iban }: ObfuscatedIbanProps): ReactNode {
  return (
    <div className={`body-1 ${cx(styles.wrapper)}`} data-test-obfuscated-iban>
      <div
        className={cx(styles['dots-wrapper'])}
        aria-hidden="true"
        data-test-obfuscated-iban-dots-wrapper
      >
        {DOTS.map(id => (
          <span className={cx(styles.dot)} key={id} data-test-obfuscated-iban-dot />
        ))}
      </div>

      <span data-test-obfuscated-iban-digits>{iban?.slice(-4)}</span>
    </div>
  );
}
