import { useMutation, useQueryClient, type UseMutateFunction } from '@tanstack/react-query';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import type { Transaction } from 'qonto/react/graphql';
import { manageActiveTransactionsQueryCache } from '../utils/react-query-helpers';
import { useOrganizationManager } from './use-organization-manager';

interface MutationVars {
  transactionIds: [string, ...string[]];
  qualifiedForAccounting: boolean;
  closePopover: () => void;
}

export const useToggleVerificationStatus = (): {
  mutate: UseMutateFunction<void, Error, MutationVars>;
  isPending: boolean;
} => {
  const queryClient = useQueryClient();
  const fetchApi = useFetchApi();
  const {
    organization: { organizationId },
  } = useOrganizationManager();

  const toggleVerificationStatus = async ({
    transactionIds,
    qualifiedForAccounting,
  }: MutationVars): Promise<void> => {
    const endpointPath = qualifiedForAccounting
      ? 'v7/transactions/disqualify_for_accounting'
      : 'v7/transactions/qualify_for_accounting';
    const response = await fetchApi(endpointPath, {
      method: 'POST',
      body: JSON.stringify({
        organization_id: organizationId,
        transaction_ids: transactionIds,
      }),
    });
    if (!response.ok) throw Error('Your changes could not be saved');
  };

  const { mutate, isPending } = useMutation({
    mutationFn: toggleVerificationStatus,
    onMutate: async ({ transactionIds, qualifiedForAccounting, closePopover }) => {
      if (transactionIds.length === 1) {
        const [transactionId] = transactionIds;
        const { transaction, updateTransactionCache, activeQueryKey } =
          manageActiveTransactionsQueryCache(transactionId, queryClient);
        await queryClient.cancelQueries({ queryKey: activeQueryKey });

        const updatedTransaction = {
          ...transaction,
          qualifiedForAccounting: !qualifiedForAccounting,
        } as Transaction;

        updateTransactionCache(updatedTransaction);
        closePopover();

        return {
          oldTransaction: transaction,
          updateTransactionCache,
        };
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['search-transactions'] });
    },
    onError: (_, __, context) => {
      if (context) {
        const { oldTransaction, updateTransactionCache } = context;
        updateTransactionCache(oldTransaction);
      }
    },
  });

  return { mutate, isPending };
};
